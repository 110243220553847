// @author Travis
import { S25Util } from "../util/s25-util";
import { DataAccess } from "../dataaccess/data.access";
import { S25Const } from "../util/s25-const";
import { Cache } from "../decorators/cache.decorator";
import { FlsService } from "./fls.service";
import { SearchCriteriaService } from "./search/search-criteria/search.criteria.service";
import { Item } from "../pojo/Item";

export class RoleService {
    private static RoleMap: any = {
        event: { url: "evcnrl.json?scope=list", root: "event_roles", fls: "ML_EVENT" },
        organization: { url: "orgcr.json?scope=list", root: "organization_roles", fls: "ML_ACCOUNT" },
    };

    @Cache({ immutable: true, targetName: "RoleService" })
    public static getEventTypeRoles(eventTypeId: number) {
        eventTypeId = S25Util.coalesce(eventTypeId, "");
        return DataAccess.get(
            DataAccess.injectCaller(
                "/eventtype/roles.json?event_type_id=" + eventTypeId,
                "RoleService.getEventTypeRoles",
            ),
        ).then(function (data) {
            return (data && data.role) || [];
        });
    }

    //type can be typeId or type name, eg "event" or 1
    @Cache({ immutable: true, targetName: "RoleService" })
    public static getRoles(type: any) {
        type = S25Const.itemId2Name[type] || type;
        return S25Util.all({
            fls: FlsService.getFls(),
            roles: DataAccess.get(DataAccess.injectCaller("/" + RoleService.RoleMap[type].url, "RoleService.getRoles")),
        }).then(function (resp) {
            let data = resp.roles;
            if (data && data.list) {
                //its a list of {item: {id, name, type, active}...
                var roleData: any = {}; //form roles from list
                roleData = [];
                data.list.item = data.list.item && S25Util.array.forceArray(data.list.item);
                if (data.list.item && data.list.item.length) {
                    data.list.item.map(function (item: any) {
                        roleData.push({
                            role_name: item.name,
                            role_id: item.id,
                            defn_state: item.active,
                        });
                    });
                    //roleData.sort(S25Util.shallowSort("role_name"));
                }
                data = roleData;
            } else {
                data = S25Util.propertyGet(data, "role");
            }

            if (["F"].indexOf(resp.fls[RoleService.RoleMap[type].fls]) == -1) {
                data = data.filter((r: any) => r.defn_state != 0);
            }
            return data;
        });
    }

    public static getRolesFiltered(
        type: Item.Ids,
    ): Promise<{ role_name: string; role_id: string; defn_state: boolean }[]> {
        const typeName = S25Const.itemId2Name[type] || type;
        let service =
            typeName === "event" ? SearchCriteriaService.getEventRoles : SearchCriteriaService.getOrganizationRoles;
        return service(true, false).then(function (roles) {
            return (
                roles &&
                roles.map((r: any) => {
                    return {
                        role_name: r.itemName,
                        role_id: r.itemId,
                        defn_state: !r.isInactive,
                    };
                })
            );
        });
    }
}
