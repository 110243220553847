/**
 * @author mikhail
 * @author devin
 * @migrated travis
 * @lastmodified 2021-12-9
 */
import { SearchModelMap } from "../s25.search.model.map";
import { SearchUtil } from "../s25.search.util";

export class SearchCriteriaUtil {
    public static getQueryString(typeId: number, modelBean: any, defaultNeedsPrefix: boolean) {
        return toUrl(typeId, modelBean, defaultNeedsPrefix);
    }
}

let toUrl = function (typeId: number, modelBean: any, defaultNeedsPrefix: boolean) {
    let map: any = SearchModelMap[typeId];
    let keywordSearchProperty =
        modelBean.keyword &&
        modelBean.keyword.value &&
        typeId === 1 &&
        modelBean.keyword.value.match(/^\d{4}-[A-Za-z]{6}$/i)
            ? "event_locator"
            : "name";
    let keyword =
        (modelBean.keyword &&
            modelBean.keyword.value &&
            SearchUtil.param2Query(
                modelBean.keyword.value,
                keywordSearchProperty,
                defaultNeedsPrefix,
                typeId,
                typeId,
            )) ||
        "";
    let arr: any = serialize(map, modelBean.searchCriteriaBean, typeId, defaultNeedsPrefix);
    if (arr && arr.length > 0) {
        arr = keyword + arr.join("");
    } else {
        arr = keyword;
    }
    return arr;
};

let serialize = function (map: any, modelBean: any, typeId: number, defaultNeedsPrefix: boolean) {
    var arr: any[] = [];
    if (modelBean) {
        for (var key in map) {
            if (map.hasOwnProperty(key)) {
                var mapField = map[key];
                var modelField = modelBean[key];

                if (key === "name") {
                    var urlFragment = SearchUtil.param2Query("", map.name, defaultNeedsPrefix, typeId, typeId);
                    var items = modelBean[map.model];

                    var match = "";
                    if (
                        map.matchingBean &&
                        map.matchingBean.name &&
                        map.matchingBean.model &&
                        modelBean &&
                        modelBean[map.matchingBean.name] &&
                        modelBean[map.matchingBean.name][map.matchingBean.model]
                    ) {
                        match = modelBean[map.matchingBean.name][map.matchingBean.model];
                        if (match === "any") {
                            match = "+";
                        } else if (match === "all") {
                            match = ",";
                        } else {
                            match = "+"; //default to any
                        }
                    }

                    //if items not an array and this is a match type, make items an array
                    if (match && items && items !== "" && Object.prototype.toString.call(items) !== "[object Array]") {
                        items = [items];
                    }

                    //if items is an array
                    if (items && Object.prototype.toString.call(items) === "[object Array]" && items.length > 0) {
                        urlFragment += items
                            .map(function (obj: any) {
                                return obj[map.subModel];
                            })
                            .join(match || "+");
                        arr.push(urlFragment);
                    } //if items is a string or number
                    else if (
                        items &&
                        items !== "" &&
                        map.name !== "matchingBean" &&
                        (typeof items === "string" || typeof items === "number")
                    ) {
                        urlFragment += items;
                        arr.push(urlFragment);
                    }
                } else if (typeof mapField === "object") {
                    arr = arr.concat(serialize(mapField, modelField, typeId, defaultNeedsPrefix));
                }
            }
        }
    }

    return arr;
};
