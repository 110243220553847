//@author: devin
import { SearchCriteriaService } from "./search.criteria.service";
import { S25Util } from "../../../util/s25-util";
import { BpeService } from "../../../modules/bpe/bpe.service";
import { MasterDefinitionTagsService } from "../../master.definitions/master.definition.tags.service";
import { MasterDefTag } from "../../../pojo/MasterDefTag";
import { SearchCriteriaType } from "../../../pojo/SearchCriteriaI";
import { GroupService } from "../../group.service";
import { BuildingService } from "../../building.service";
import { TaxesService } from "../../taxes.service";
import { RateSchedulePayload } from "../../../pojo/RateScheduleI";
import TaxI = RateSchedulePayload.TaxI;
import { EventCreationFormService } from "../../event.creation.form.service";

export interface SearchCriteriaContextI {
    domainFilter?: any;
    excludeResultTitle: boolean;
    expensiveSearch?: boolean;
    extractItems?: Function;
    hasFilter: boolean;
    hasQuantity?: boolean;
    hasScroll?: boolean;
    hasSelectAll: boolean;
    hasSelectNone: boolean;
    hasTags?: boolean;
    hasCategories?: boolean;
    customFilterMethod?: Function;
    itemTypeId?: number;
    matchingBean?: any;
    preFetchItems?: boolean;
    serviceMethod: Function;
    showFavorite?: boolean;
    showMatching?: boolean;
    title: string;
}

export class SearchCriteriaContext {
    public static Context: { [key in SearchCriteriaType["type"]]?: SearchCriteriaContextI } = {
        securityGroups: {
            serviceMethod: function () {
                return SearchCriteriaService.getSecurityGroups.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            title: "Security Groups",
            excludeResultTitle: true,
            hasCategories: true,
            customFilterMethod: function () {
                return GroupService.getGroupCategoriesForFilter.apply(this, [].slice.call(arguments));
            },
        },
        eventTypeReports: {
            serviceMethod: function () {
                return SearchCriteriaService.getEventTypeReports.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            title: "Reports",
            excludeResultTitle: true,
        },
        assignedEventStates: {
            serviceMethod: function () {
                return SearchCriteriaService.getAssignedEventStates.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            title: "States",
            excludeResultTitle: true,
        },
        eventStates: {
            serviceMethod: function () {
                return SearchCriteriaService.getEventStates.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            title: "States",
            excludeResultTitle: true,
        },
        eventTypes: {
            itemTypeId: 7,
            serviceMethod: function () {
                return SearchCriteriaService.getEventTypes.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            title: "Types",
            excludeResultTitle: true,
        },
        eventFolders: {
            serviceMethod: function () {
                return SearchCriteriaService.getFolders.apply(this, [].slice.call(arguments));
            },
            preFetchItems: true,
            hasScroll: true,
            extractItems: SearchCriteriaService.extractItems,
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            expensiveSearch: true,
            title: "Folders",
            excludeResultTitle: true,
        },
        eventCabinets: {
            serviceMethod: function () {
                return SearchCriteriaService.getEventCabinets.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            title: "Cabinets",
            excludeResultTitle: true,
        },

        events: {
            itemTypeId: 1,
            serviceMethod: function () {
                return SearchCriteriaService.getEvents.apply(this, [].slice.call(arguments));
            },
            preFetchItems: true,
            hasScroll: true,
            extractItems: SearchCriteriaService.extractItems,
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            expensiveSearch: true,
            domainFilter: {
                prefix: "&query_id=",
                filterMethod: function () {
                    return SearchCriteriaService.getEventSearches.apply(this, [].slice.call(arguments));
                },
            },
            title: "Events",
            excludeResultTitle: true,
        },
        eventSearches: {
            serviceMethod: function () {
                return SearchCriteriaService.getEventSearches.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            title: "Searches",
            excludeResultTitle: true,
        },
        eventCategories: {
            serviceMethod: function () {
                return SearchCriteriaService.getEventCategories.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            hasTags: true,
            title: "Categories",
            excludeResultTitle: true,
            showMatching: true,
            matchingBean: {},
        },
        eventRequirements: {
            serviceMethod: function () {
                return SearchCriteriaService.getEventRequirements.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            title: "Requirements",
            excludeResultTitle: true,
        },
        eventRoles: {
            serviceMethod: function () {
                return SearchCriteriaService.getEventRoles.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            title: "Roles",
            excludeResultTitle: true,
        },
        eventCustomAttributes: {
            serviceMethod: function () {
                return SearchCriteriaService.getEventCustomAttributes.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            title: "Custom Attributes",
            excludeResultTitle: true,
        },

        locationRelatedTypes: {
            serviceMethod: function () {
                return SearchCriteriaService.getLocationRelatedTypes.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            title: "Types",
            excludeResultTitle: true,
        },
        locationSearches: {
            serviceMethod: function () {
                return SearchCriteriaService.getLocationSearches.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            title: "Searches",
            excludeResultTitle: true,
        },
        locations: {
            itemTypeId: 4,
            serviceMethod: function () {
                return SearchCriteriaService.getLocations.apply(this, [].slice.call(arguments));
            },
            hasScroll: true,
            extractItems: SearchCriteriaService.extractItems,
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            showFavorite: true,
            expensiveSearch: true,
            domainFilter: {
                prefix: "&query_id=",
                filterMethod: function () {
                    return SearchCriteriaService.getLocationSearches.apply(this, [].slice.call(arguments));
                },
            },
            title: "Locations",
            excludeResultTitle: true,
        },
        locationFeatures: {
            serviceMethod: function () {
                return SearchCriteriaService.getSpaceFeatures.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            hasTags: true,
            title: "Features",
            excludeResultTitle: true,
            showMatching: true,
            matchingBean: {},
        },
        locationCategories: {
            serviceMethod: function () {
                return SearchCriteriaService.getSpaceCategories.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            hasTags: true,
            title: "Categories",
            excludeResultTitle: true,
            showMatching: true,
            matchingBean: {},
        },
        locationPartitions: {
            serviceMethod: function () {
                return SearchCriteriaService.getLocationPartitions.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            title: "Partitions",
            excludeResultTitle: true,
        },
        locationLayouts: {
            serviceMethod: function () {
                return SearchCriteriaService.getSpaceLayouts.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            title: "Layouts",
            excludeResultTitle: true,
            showMatching: true,
            matchingBean: {},
        },
        locationCustomAttributes: {
            serviceMethod: function () {
                return SearchCriteriaService.getLocationCustomAttributes.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            title: "Custom Attributes",
            excludeResultTitle: true,
        },

        resourceSearches: {
            serviceMethod: function () {
                return SearchCriteriaService.getResourceSearches.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            title: "Searches",
            excludeResultTitle: true,
        },
        resources: {
            itemTypeId: 6,
            serviceMethod: function () {
                return SearchCriteriaService.getResources.apply(this, [].slice.call(arguments));
            },
            hasScroll: true,
            extractItems: SearchCriteriaService.extractItems,
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            showFavorite: true,
            expensiveSearch: true,
            domainFilter: {
                prefix: "&category_id=",
                filterMethod: function () {
                    return SearchCriteriaService.getResourceCategories.apply(this, [].slice.call(arguments));
                },
            },
            title: "Resources",
            excludeResultTitle: true,
        },
        resourcesQuantity: {
            itemTypeId: 607,
            hasQuantity: true,
            serviceMethod: function () {
                return SearchCriteriaService.getResourcesQuantity.apply(this, [].slice.call(arguments));
            },
            hasScroll: true,
            extractItems: (data: any) => {
                return SearchCriteriaService.extractItems(data).reduce((result: any, item: any) => {
                    // inject itemTypeId into each item, so s25-multiselect-item handles them properly (i.e. shows number picker)
                    S25Util.extend(item, {
                        itemTypeId: SearchCriteriaContext.Context.resourcesQuantity.itemTypeId,
                        quantityMax: item.quantity,
                        quantity: undefined,
                    });
                    // only include item.quantity > 0
                    return item.quantityMax > 0 ? result.concat(item) : result;
                }, []);
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            showFavorite: true,
            expensiveSearch: true,
            domainFilter: {
                prefix: "&category_id=",
                filterMethod: function () {
                    return SearchCriteriaService.getResourceCategories.apply(this, [].slice.call(arguments));
                },
            },
            title: "Resources",
            excludeResultTitle: true,
        },
        resourceCategories: {
            serviceMethod: function () {
                return SearchCriteriaService.getResourceCategories.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            hasTags: true,
            title: "Categories",
            excludeResultTitle: true,
            showMatching: true,
            matchingBean: {},
        },
        resourceCustomAttributes: {
            serviceMethod: function () {
                return SearchCriteriaService.getResourceCustomAttributes.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            title: "Custom Attributes",
            excludeResultTitle: true,
        },

        organizations: {
            itemTypeId: 2,
            serviceMethod: function () {
                return SearchCriteriaService.getEventOrganizations.apply(this, [].slice.call(arguments));
            },
            hasScroll: true,
            extractItems: SearchCriteriaService.extractItems,
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            showFavorite: true,
            expensiveSearch: true,
            domainFilter: {
                prefix: "&type_id=",
                filterMethod: function () {
                    return SearchCriteriaService.getOrganizationTypes.apply(this, [].slice.call(arguments));
                },
            },
            title: "Organizations",
            excludeResultTitle: true,
        },
        organizationSearches: {
            serviceMethod: function () {
                return SearchCriteriaService.getOrganizationSearches.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            title: "Searches",
            excludeResultTitle: true,
        },
        organizationTypes: {
            serviceMethod: function () {
                return SearchCriteriaService.getOrganizationTypes.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            title: "Types",
            excludeResultTitle: true,
        },
        organizationCategories: {
            serviceMethod: function () {
                return SearchCriteriaService.getOrganizationCategories.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            hasTags: true,
            title: "Categories",
            excludeResultTitle: true,
            showMatching: true,
            matchingBean: {},
        },
        organizationRatings: {
            serviceMethod: function () {
                return SearchCriteriaService.getOrganizationRatings.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            title: "Ratings",
            excludeResultTitle: true,
        },
        organizationCustomAttributes: {
            serviceMethod: function () {
                return SearchCriteriaService.getOrganizationCustomAttributes.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            title: "Custom Attributes",
            excludeResultTitle: true,
        },
        organizationRoles: {
            serviceMethod: function () {
                return SearchCriteriaService.getOrganizationRoles.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: false,
            hasFilter: true,
            title: "Roles",
            excludeResultTitle: true,
        },

        contacts: {
            itemTypeId: 3,
            serviceMethod: function () {
                return SearchCriteriaService.getContacts.apply(this, [].slice.call(arguments));
            },
            preFetchItems: true,
            hasScroll: true,
            title: "Contacts",
            excludeResultTitle: true,
            expensiveSearch: true,
            extractItems: SearchCriteriaService.extractItems,
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            showFavorite: true,
        },
        emailTemplates: {
            serviceMethod: function () {
                return BpeService.getFullTemplates.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: false,
            hasFilter: true,
            title: "Templates",
            excludeResultTitle: true,
        },
        manualEmailTemplates: {
            serviceMethod: function () {
                return SearchCriteriaService.getManualTemplates.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            title: "Manual Email Templates",
            excludeResultTitle: true,
        },
        systemTags: {
            serviceMethod: function () {
                return MasterDefinitionTagsService.getTags
                    .apply(this, [].slice.call(arguments))
                    .then((resp: MasterDefTag.TagWrapperI[]) => {
                        return resp.map((tag) => {
                            return {
                                itemId: tag.tag_id,
                                itemName: tag.tag_name,
                            };
                        });
                    });
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            title: "Tags",
            excludeResultTitle: true,
        },
        todoTemplates: {
            serviceMethod: function () {
                return BpeService.getFullTodoTemplates.apply(this, [].slice.call(arguments));
            },
            hasSelectNone: true,
            hasSelectAll: false,
            hasFilter: true,
            title: "To Do Templates",
            excludeResultTitle: true,
        },
        buildings: {
            serviceMethod: function () {
                return BuildingService.getBuildings.apply(this, [].slice.call(arguments)).then((resp: any) => {
                    if (resp && resp.items) {
                        return resp.items.map((b: any) => {
                            return {
                                itemId: b.id,
                                itemName: b.buildingName,
                            };
                        });
                    } else {
                        return [];
                    }
                });
            },
            hasSelectNone: true,
            hasSelectAll: true,
            hasFilter: true,
            title: "Buildings",
            excludeResultTitle: true,
        },
        taxSchedules: {
            serviceMethod: function () {
                return TaxesService.getTaxes.apply(this, [].slice.call(arguments)).then((resp: any) => {
                    return (
                        resp?.map((tax: TaxI) => ({
                            itemId: tax.tax_id,
                            itemName: tax.tax_name,
                        })) || []
                    );
                });
            },
            hasFilter: true,
            hasSelectAll: true,
            hasSelectNone: true,
            excludeResultTitle: true,
            title: "Tax Schedules",
        },
        standardSchedules: {
            serviceMethod: function () {
                return SearchCriteriaService.getStandardSchedules.apply(this, [].slice.call(arguments));
            },
            hasFilter: true,
            hasSelectAll: false,
            hasSelectNone: false,
            excludeResultTitle: true,
            title: "Standard Meeting Patterns",
        },
        matchQuestions: {
            // todo: refactor all serviceMethod entries here to accept a single typed object with all possible
            //  search criteria parameters; then create search criteria service entries all all calls in this
            //  file that call other services directly. That way each search crit context calls a search crit
            //  service method with the 1 object parameter. Each search crit service method then destructures that
            //  object and calls the appropriate service method with the appropriate parameters.
            serviceMethod: function (
                useSecurity?: boolean,
                activeOnly?: boolean,
                customFilterValue?: string,
                // bc dropdown calls 4 params with the 4th as customFilterValue while multiselect calls 3 params with the 3rd as customFilterValue
                customFilterValueOverload?: string,
            ) {
                return SearchCriteriaService.getQuestionItems(
                    S25Util.parseInt(customFilterValue || customFilterValueOverload),
                );
            },
            hasFilter: true,
            hasSelectAll: false,
            hasSelectNone: false,
            excludeResultTitle: true,
            title: "Questions",
        },
        matchQuestionsAll: {
            serviceMethod: function (
                useSecurity?: boolean,
                activeOnly?: boolean,
                customFilterValue?: string,
                // bc dropdown calls 4 params with the 4th as customFilterValue while multiselect calls 3 params with the 3rd as customFilterValue
                customFilterValueOverload?: string,
            ) {
                return SearchCriteriaService.getAllQuestionItems(
                    S25Util.parseInt(customFilterValue || customFilterValueOverload),
                );
            },
            hasFilter: true,
            hasSelectAll: false,
            hasSelectNone: false,
            excludeResultTitle: true,
            title: "Questions",
        },
        formConfigs: {
            serviceMethod: function () {
                return EventCreationFormService.getAllConfigs.apply(this, [].slice.call(arguments));
            },
            hasFilter: true,
            hasSelectAll: true,
            hasSelectNone: true,
            excludeResultTitle: true,
            title: "Form Configs",
        },
    };
}
