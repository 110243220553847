export const SearchCriteriaBeanNameMap: any = {
    1: {
        cabinetsBean: { stepTypeId: 105 },
        categoriesBean: { stepTypeId: 120 },
        organizationsBean: { stepTypeId: 110 },
        rolesBean: { stepTypeId: 111 },
        statesBean: { stepTypeId: 101 },
        typesBean: { stepTypeId: 102 },
    },
    4: {
        capacityBean: { stepTypeId: 400, subTypeId: 8 },
        featuresBean: { stepTypeId: 410 },
        layoutsBean: { stepTypeId: 430 },
        categoriesBean: { stepTypeId: 420 },
        buildingsBean: { stepTypeId: 425 },
    },
    2: {
        typesBean: { stepTypeId: 230 },
        categoriesBean: { stepTypeId: 220 },
    },
    6: {
        categoriesBean: { stepTypeId: 620 },
    },
};

export const SearchModelMap: any = {
    4: {
        keyword: "keyword",
        capacityBean: {
            minBean: {
                name: "min_capacity",
                model: "model",
            },
            maxBean: {
                name: "max_capacity",
                model: "model",
            },
        },
        categoriesBean: {
            name: "category_id",
            model: "selectedItems",
            subModel: "itemId",
            matchingBean: {
                name: "matchingBean",
                model: "model",
            },
        },
        featuresBean: {
            name: "feature_id",
            model: "selectedItems",
            subModel: "itemId",
            matchingBean: {
                name: "matchingBean",
                model: "model",
            },
        },
        layoutsBean: {
            name: "layout_id",
            model: "selectedItems",
            subModel: "itemId",
            matchingBean: {
                name: "matchingBean",
                model: "model",
            },
        },
        buildingsBean: {
            name: "building_id",
            model: "selectedItems",
            subModel: "itemId",
            matchingBean: {
                name: "matchingBean",
                model: "model",
            },
        },
    },

    1: {
        keyword: "keyword",
        organizationsBean: {
            name: "organization_id",
            model: "selectedItems",
            subModel: "itemId",
            matchingBean: {
                name: "matchingBean",
                model: "model",
            },
        },
        typesBean: {
            name: "event_type_id",
            model: "selectedItems",
            subModel: "itemId",
            matchingBean: {
                name: "matchingBean",
                model: "model",
            },
        },
        categoriesBean: {
            name: "category_id",
            model: "selectedItems",
            subModel: "itemId",
            matchingBean: {
                name: "matchingBean",
                model: "model",
            },
        },
        cabinetsBean: {
            name: "cabinet_id",
            model: "selectedItems",
            subModel: "itemId",
            matchingBean: {
                name: "matchingBean",
                model: "model",
            },
        },
        statesBean: {
            name: "state",
            model: "selectedItems",
            subModel: "itemId",
            matchingBean: {
                name: "matchingBean",
                model: "model",
            },
        },
        rolesBean: {
            name: "role_id",
            model: "selectedItems",
            subModel: "itemId",
            matchingBean: {
                name: "matchingBean",
                model: "model",
            },
        },
    },

    6: {
        keyword: "keyword",
        categoriesBean: {
            name: "category_id",
            model: "selectedItems",
            subModel: "itemId",
            matchingBean: {
                name: "matchingBean",
                model: "model",
            },
        },
    },

    2: {
        keyword: "keyword",
        typesBean: {
            name: "type_id",
            model: "selectedItems",
            subModel: "itemId",
            matchingBean: {
                name: "matchingBean",
                model: "model",
            },
        },
        categoriesBean: {
            name: "category_id",
            model: "selectedItems",
            subModel: "itemId",
            matchingBean: {
                name: "matchingBean",
                model: "model",
            },
        },
    },
};
