import { SearchCriteria } from "../../pojo/SearchCriteria";
import { Item } from "../../pojo/Item";
import { SearchService } from "./search.service";
import { S25Util } from "../../util/s25-util";

export class SearchUtil {
    public static typeId2SearchPrefix(searchTypeId: number) {
        if (searchTypeId === 1) {
            return "events_";
        } else if (searchTypeId === 2) {
            return "organizations_";
        } else if (searchTypeId === 4) {
            return "spaces_";
        } else if (searchTypeId === 6) {
            return "resources_";
        } else if (searchTypeId === 10) {
            return "";
        } else {
            return "";
        }
    }

    public static param2Query(
        paramValue: string | number,
        paramName: string,
        defaultNeedsPrefix: boolean,
        subjId: number,
        searchTypeId: number,
        encodeURI = true,
    ) {
        const encoder = encodeURI ? encodeURIComponent : (a: any) => a;
        if (searchTypeId > 100) {
            searchTypeId -= 100;
        }
        if (!defaultNeedsPrefix) {
            //eg, list
            if (subjId === searchTypeId) {
                return "&" + paramName + "=" + encoder(paramValue);
            } else {
                return (
                    "&" + SearchUtil.typeId2SearchPrefix(searchTypeId || subjId) + paramName + "=" + encoder(paramValue)
                );
            }
        } else {
            //calendar, availability
            return "&" + SearchUtil.typeId2SearchPrefix(searchTypeId || subjId) + paramName + "=" + encoder(paramValue);
        }
    }

    public static queryId2Query(
        queryId: number | string,
        defaultNeedsPrefix: boolean,
        subjId: number,
        searchTypeId: number,
    ) {
        return SearchUtil.param2Query(queryId, "query_id", defaultNeedsPrefix, subjId, searchTypeId);
    }

    public static queryParam2Id(param: string) {
        return param && param.split && param.split("=").length > 1 && parseInt(param.split("=")[1]);
    }

    public static async createSearch(
        model: SearchCriteria.Model,
        searches: SearchCriteria.Searches,
        itemType: Item.Id,
        name?: string,
    ): Promise<{ id: number; name: string; tempIds: number[] }> {
        model = S25Util.deepCopy(model);
        name ||= `temp_${S25Util.generateQuickGUID()}`;
        const tempSearchSteps = model.step.filter(SearchUtil.isStepTemp);
        const tempSearches = await Promise.all(
            tempSearchSteps.map(async (step) => {
                const key = step.step_param[0].itemName;
                const newSearch = await SearchUtil.createSearch(searches[key], searches, itemType);
                step.step_param[0].itemId = newSearch.id;
                step.step_param[0].itemName = newSearch.name;
                return newSearch;
            }),
        );
        const newSearch = await SearchService.putJsonSearch(itemType, model, null, true, name, false, false);

        return {
            id: newSearch.queryId,
            name,
            tempIds: S25Util.array.flatten(tempSearches.map((search) => [search.id, search.tempIds])),
        };
    }

    public static getTempSearchQueryGenerator(
        model: SearchCriteria.Model,
        searches: SearchCriteria.Searches,
        itemType: Item.Id,
        defaultNeedsPrefix: boolean,
    ) {
        return async () => {
            const { id, tempIds } = await SearchUtil.createSearch(model, searches, itemType);
            const query = SearchUtil.queryId2Query(id, defaultNeedsPrefix, itemType, itemType);
            const postSearch = () => {
                // Delete all searches
                return Promise.all([id, ...tempIds].map((id) => SearchService.deleteSearch(id, itemType)));
            };

            return { query, postSearch };
        };
    }

    public static isStepTemp(step: SearchCriteria.Step) {
        const stepType = Number(step.step_type_id); // Event search is 108, all others are X05
        const isSearch =
            stepType === SearchCriteria.StepType.Event.EventSearch || (stepType !== 105 && stepType % 100 === 5);
        return isSearch && step.step_param?.[0]?.itemName?.startsWith?.("temp_");
    }
}
