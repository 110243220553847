//@author: devin
import { DataAccess } from "../../dataaccess/data.access";
import { S25Util } from "../../util/s25-util";
import { ContactService } from "../contact.service";
import { jSith } from "../../util/jquery-replacement";
import { Cache, CacheRepository, Invalidate } from "../../decorators/cache.decorator";
import { PreferenceService } from "../preference.service";
import { TaskService } from "../task/task.service";
import { LangService } from "../lang.service";
import { SearchCriteriaUtil } from "./search-criteria/search.criteria.util";
import { SearchModelBackwardLookup } from "./s25.search.model.backward.lookup.map";
import { S25Const } from "../../util/s25-const";
import { SearchUtil } from "./s25.search.util";
import { GroupService } from "../group.service";
import { ModalService } from "../../modules/modal/modal.service";
import { AdvancedSearchUtil } from "../../modules/advanced-search/advanced-search-util";
import { UserprefService } from "../userpref.service";
import { SearchCriteriaBeanNameMap } from "./s25.search.model.map";
import { FlsService } from "../fls.service";
import { Timeout } from "../../decorators/timeout.decorator";
import { Item } from "../../pojo/Item";
import { SearchCriteria } from "../../pojo/SearchCriteria";
import { Proto } from "../../pojo/Proto";
import { QLUtil } from "../../modules/s25-ql/s25ql.util";
import NumericalString = Proto.NumericalString;
import { MaybeArray } from "../../pojo/Util";

export const SEARCH_MODEL_SKIP_LIST: any = {
    list: [
        "prefix",
        "checked",
        "from_dt_date",
        "from_dt_num",
        "from_dt_num_dir",
        "from_dt_type",
        "until_dt_date",
        "until_dt_num",
        "until_dt_num_dir",
        "until_dt_type",
        "isOwner",
        "val",
        "previousItemId",
    ],
};

export const SAVE_SEARCH_MAP: any = {
    1: {
        rootName: "event_search",
        itemUrl: "events.json?query_id=",
        itemSearchTypeId: 108,
        searchLang: "s25-event_search_advanced",
    },
    4: {
        rootName: "space_search",
        itemUrl: "spaces.json?query_id=",
        itemSearchTypeId: 405,
        searchLang: "s25-space_search_advanced",
    },
    6: {
        rootName: "resource_search",
        itemUrl: "resources.json?query_id=",
        itemSearchTypeId: 605,
        searchLang: "s25-resource_search_advanced",
    },
    2: {
        rootName: "organization_search",
        itemUrl: "organizations.json?query_id=",
        itemSearchTypeId: 205,
        searchLang: "s25-organization_search_advanced",
    },
    10: {
        rootName: "task_search",
        itemUrl: "tasks.json?query_id=",
        itemSearchTypeId: 1000,
        searchLang: "s25-task_search_advanced",
    },
};

const EMPTY_PARAM_ALLOWED: any = {
    1: { 10: true, 174: true, 175: true, 176: true },
    4: { 10: true },
    2: { 10: true },
    6: { 10: true },
    10: {},
};

const SEARCH_TYPE_ID_MAP: any = {
    1: {
        name: "event",
        searchUrl: "/event_search.json",
        copyUrl: "/event_search.copyto",
        listUrl: "/event_searches.json?scope=list",
    },
    3: { name: "contact", searchUrl: "/contacts.json", copyUrl: null, listUrl: "/contacts.json?scope=list" },
    4: {
        name: "space",
        searchUrl: "/space_search.json",
        copyUrl: "/space_search.copyto",
        listUrl: "/space_searches.json?scope=list",
    },
    6: {
        name: "resource",
        searchUrl: "/resource_search.json",
        copyUrl: "/resource_search.copyto",
        listUrl: "/res_searches.json?scope=list",
    },
    2: {
        name: "org",
        searchUrl: "/org_search.json",
        copyUrl: "/org_search.copyto",
        listUrl: "/org_searches.json?scope=list",
    },
    10: {
        name: "task",
        searchUrl: "/task_search.json",
        copyUrl: "/task_search.copyto",
        listUrl: "/task_searches.json?scope=list",
    },
};

const SEARCH_GET_MAP: any = {
    1: {
        searchCriteria: "event_search.json?query_id=",
        searchCriteriaBase: "event_search",
    },
    4: {
        searchCriteria: "space_search.json?query_id=",
        searchCriteriaBase: "space_search",
    },
    6: {
        searchCriteria: "resource_search.json?query_id=",
        searchCriteriaBase: "resource_search",
    },
    2: {
        searchCriteria: "org_search.json?query_id=",
        searchCriteriaBase: "organization_search",
    },
    10: {
        searchCriteria: "task_search.json?query_id=",
        searchCriteriaBase: "task_search",
    },
};

const SEARCH_GET_CUSTOM_KEY_MAP: any = {
    keyword: "itemName",
    event_name: "itemName",
    event_id: "itemId",
    event_name_search_method: "searchMethod",
    event_title_search_method: "searchMethod",
    space_name_search_method: "searchMethod",
    resource_name_search_method: "searchMethod",
    organization_name_search_method: "searchMethod",
    event_state_name: "itemName",
    event_state_id: "itemId",
    event_type_name: "itemName",
    event_type_id: "itemId",
    event_folder_name: "itemName",
    event_folder_id: "itemId",
    event_cabinet_name: "itemName",
    event_cabinet_id: "itemId",
    event_search_name: "itemName",
    event_search_id: "itemId",
    event_exclude_search_name: "itemName",
    event_exclude_search_id: "itemId",
    organization_search_name: "itemName",
    organization_search_id: "itemId",
    space_search_name: "itemName",
    space_search_id: "itemId",
    resource_search_name: "itemName",
    resource_search_id: "itemId",
    contact_name: "itemName",
    contact_id: "itemId",
    attendee: "itemId",
    attendee_name: "itemName",
    organization_name: "itemName",
    organization_id: "itemId",
    event_requirement_name: "itemName",
    event_requirement_id: "itemId",
    event_category_name: "itemName",
    event_category_id: "itemId",
    standard_schedule_id: "itemId",
    standard_schedule_name: "itemName",
    space_name: "itemName",
    space_id: "itemId",
    resource_name: "itemName",
    resource_id: "itemId",
    cust_atrb_value: "itemId",
    cust_atrb_detail: "itemName",
    related_space_name: "itemName",
    related_space_id: "itemId",
    category_id: "itemId",
    category_name: "itemName",
    layout_id: "itemId",
    layout_name: "itemName",
    feature_id: "itemId",
    feature_name: "itemName",
    type_name: "itemName",
    type_id: "itemId",
    rating_name: "itemName",
    rating_id: "itemId",
    step_type: "step_type_id",
    partition_name: "itemName",
    partition_id: "itemId",
    building_name: "itemName",
    building_id: "itemId",
};

export class SearchService {
    /*
     *  Search Runner section
     */
    @Timeout
    public static getSearchQuery(
        searchType: number,
        defaultNeedsPrefix: boolean,
        subjId: Item.Id,
        searchModel: SearchCriteria.Model & {
            obj: { val: string; itemId: number | string; itemTypeId: number | string };
        },
        defaultKeyword: string,
    ) {
        defaultNeedsPrefix = S25Util.toBool(defaultNeedsPrefix);
        return LangService.getLang().then(function (lang) {
            let searchIsInvalid = SearchService.isInvalidSearch(
                true,
                searchType,
                searchModel,
                lang,
                subjId,
                defaultKeyword,
            );
            if (!searchIsInvalid) {
                switch (
                    searchType //1: simple search, 2: pre-defined search, 3: temp search
                ) {
                    case 1:
                        return SearchCriteriaUtil.getQueryString(subjId, searchModel, defaultNeedsPrefix);
                    case 2:
                        if (searchModel && searchModel.obj && searchModel.obj.val) {
                            if (searchModel.obj.itemId && S25Util.isNumeric(searchModel.obj.itemId)) {
                                //query id
                                return SearchUtil.queryId2Query(
                                    searchModel.obj.itemId,
                                    defaultNeedsPrefix,
                                    subjId,
                                    searchModel.obj.itemTypeId as number,
                                );
                            } else if (
                                [1, 2, 3, 4, 6, 10].indexOf(parseInt(searchModel.obj.itemTypeId as string) - 100) === -1
                            ) {
                                //no item type info (capacity or keyword search (deprecate?)
                                return searchModel.obj.val
                                    .split("&")
                                    .map(function (s: any) {
                                        //split params
                                        let query = s.split("="); //split each param into param and value
                                        let param = query[0],
                                            value = query[1];
                                        if (param && value) {
                                            //do not process blank or undef parameters (eg &this=that splits to [undef, this=that]...
                                            return SearchUtil.param2Query(
                                                value,
                                                param,
                                                defaultNeedsPrefix,
                                                subjId,
                                                subjId,
                                            ); //get query based on viz and subject
                                        } else {
                                            return "";
                                        }
                                    })
                                    .join(""); //merge together again
                            } else {
                                //some other predef search using ws attributes, like space_favorite=T
                                let query = searchModel.obj.val;

                                //drop down searches have object type prefixed to favorite for favorite searches
                                //this is only valid for reservation type searches. If the subject is just a plain object search we switch it to just
                                //"favorite" instead of, say, "event_favorite" for an event search
                                if (!defaultNeedsPrefix) {
                                    query = query.replace(
                                        S25Const.itemId2Name[subjId].replace("location", "space") + "_favorite",
                                        "favorite",
                                    );
                                    query = query.replace("spaces_favorite", "favorite");
                                    query = query.replace("spaces_direct", "direct");
                                    query = query.replace("spaces_can_assign", "can_assign");
                                    query = query.replace("spaces_min_ols", "min_ols");
                                }

                                return query;
                            }
                        }
                        break;
                    case 3:
                        return jSith.when(-1); //temp search must be created at run time via modelBean.tempSearchFn in the viz component. Note runSearch sets this for us
                }
            } else {
                return null;
            }
        });
    }

    public static getPreDefinedSearchQuery(
        itemTypeId: number,
        query: string,
        queryItemTypeId: number | string,
        tab: string,
        isPredefined = false,
        encodeURI = true,
    ) {
        if (
            !isPredefined &&
            ((itemTypeId === Item.Ids.Event && queryItemTypeId !== Item.Ids.Event) || tab !== "list")
        ) {
            query = query
                .split("&")
                .map((s) => {
                    let [param, value] = s.split("=");
                    if (!param || !value) return "";
                    return SearchUtil.param2Query(value, param, true, itemTypeId, itemTypeId, encodeURI);
                })
                .join("");
        } else if (tab === "list") {
            query = query.replace(
                S25Const.itemId2Name[itemTypeId].replace("location", "space") + "_favorite",
                "favorite",
            );
            query = query.replace("spaces_favorite", "favorite");
            query = query.replace("spaces_direct", "direct");
            query = query.replace("spaces_can_assign", "can_assign");
            query = query.replace("spaces_min_ols", "min_ols");
        }

        return query;
    }

    @Timeout
    public static runSearch(
        searchType: number,
        defaultNeedsPrefix: boolean,
        subjId: Item.Id,
        searchModelIn: SearchCriteria.Model & { obj: { val: string; itemId: number | string; itemTypeId: number } },
        defaultKeyword: string,
        modelBean: any,
        runFun: () => any,
    ) {
        defaultNeedsPrefix = S25Util.toBool(defaultNeedsPrefix);
        let searchModel = S25Util.deepCopy(searchModelIn); //we don't want any side-effects on searchModelIn when running a temp search, for example
        return LangService.getLang().then(function (lang) {
            return SearchService.getSearchQuery(
                searchType,
                defaultNeedsPrefix,
                subjId,
                searchModel,
                defaultKeyword,
            ).then(function (searchQuery) {
                if (searchQuery) {
                    modelBean.searchQuery = searchQuery; //note if temp search, then tempSearchFn will reset searchQuery before getdata is called
                    modelBean.tempSearchFn =
                        searchType === 3 &&
                        function () {
                            let searchIsInvalid = SearchService.isInvalidSearch(
                                true,
                                searchType,
                                searchModel,
                                lang,
                                subjId,
                                defaultKeyword,
                            );
                            if (!searchIsInvalid) {
                                //search is valid / OK, so return temp-search creation and thus searchQuery and deletion fun to call after running temp search
                                return SearchService.getTempSearch(subjId, searchModel).then(function (
                                    queryId: number,
                                ) {
                                    return {
                                        invalidSearch: searchIsInvalid,
                                        searchQuery: SearchUtil.queryId2Query(
                                            queryId,
                                            defaultNeedsPrefix,
                                            subjId,
                                            subjId,
                                        ), //subj and search type are the same for temp searches
                                        searchDelete: function () {
                                            SearchService.deleteSearch(queryId, subjId);
                                        },
                                    };
                                });
                            } else if (searchIsInvalid) {
                                return jSith.when({ invalidSearch: searchIsInvalid }); //not a temp search, return dummy promise for dao to call "then" (ex: s25-list-service)
                            } else {
                                return jSith.when(); //not a temp search, return dummy promise for dao to call "then" (ex: s25-list-service)
                            }
                        };
                    return runFun();
                } else {
                    return null;
                }
            });
        });
    }

    /*
     * Search GET section
     */

    /**
     *
     * @param typeId Integer, new query_id
     * @returns Promise<number>
     */
    public static _getNewSearchId(typeId: number) {
        return DataAccess.post(
            DataAccess.injectCaller(SEARCH_TYPE_ID_MAP[typeId].searchUrl, "SearchService.getNewSearchId"),
        );
    }

    @Timeout
    public static getNewSearchId(typeId: number) {
        return SearchService._getNewSearchId(typeId).then(function (data) {
            let query_id = S25Util.propertyGet(S25Util.prettifyJson(data), "query_id");
            return query_id;
        });
    }

    @Timeout
    public static getTempSearch(typeId: number, json: any) {
        return SearchService.putJsonSearch(
            typeId,
            json,
            null,
            true,
            "temp_" + S25Util.generateQuickGUID(),
            false,
            false,
        ).then(function (ret: any) {
            return ret.queryId;
        });
    }

    //TODO: use QLUtil.isTemp once migrated (or update that to use SearchService.isTemp (this might be clearer))
    public static isTemp(name: string): boolean {
        return name && name.indexOf("temp_") === 0;
    }

    public static isBackgroundSearch(name: string): boolean {
        return name && name.indexOf("Search_") === 0;
    }

    public static isVisibleSearch(name: string): boolean {
        return !SearchService.isTemp(name) && !SearchService.isBackgroundSearch(name);
    }

    //Get searches dao
    public static _getSearches(typeId: number) {
        return DataAccess.get<{
            opt: {
                grp: string;
                itemTypeId: number;
                txt: string;
                type: string;
                val: string;
            }[];
        }>("/searches/list.json?type_id=" + typeId);
    }

    @Timeout
    @Cache({ targetName: "SearchService", immutable: true })
    public static getSearches(typeId: number) {
        return S25Util.all({
            currContId: ContactService.getCurrentId(),
            searches: SearchService._getSearches(typeId),
        }).then(
            (resp: {
                currContId: Awaited<ReturnType<typeof ContactService.getCurrentId>>;
                searches: Awaited<ReturnType<typeof SearchService._getSearches>>;
            }) => {
                resp.searches.opt = resp.searches.opt || [];
                let searches = resp.searches && resp.searches.opt;
                jSith.forEach(searches, function (_: any, s: any) {
                    //force search name to be string
                    if (s && "txt" in s) {
                        s.txt += "";
                    }

                    if (s && s.val && s.val.indexOf && s.val.indexOf("{{currentContactId}}") > -1) {
                        s.val = s.val.replace("{{currentContactId}}", resp.currContId);
                    }
                });

                resp.searches.opt = searches.filter(function (s: any) {
                    return s && s.txt && SearchService.isVisibleSearch(s.txt);
                });

                return resp.searches;
            },
        );
    }

    @Timeout
    public static getStrictSearches(typeId: number, allowNonQueryId?: boolean) {
        return SearchService.getSearches(typeId).then(function (data) {
            let searches = (data && data.opt) || [];
            data.opt = searches.filter(function (s: any) {
                return (!!s.itemId || allowNonQueryId) && parseInt(s.itemTypeId) === 100 + S25Util.toInt(typeId); //only valid query ids AND queries of the same object type
            });
            return data;
        });
    }

    @Timeout
    @Invalidate({ serviceName: "SearchService", methodName: "getSearches", objectFunc: (args) => [args[0]] })
    public static reloadStrictSearches(typeId: number, allowNonQueryId?: boolean) {
        return SearchService.getStrictSearches(typeId, allowNonQueryId);
    }

    public static stripAllTimezone(json: any) {
        S25Util.replaceDeep(json, {
            between_start_dt: S25Util.date.dropTZString,
            between_end_dt: S25Util.date.dropTZString,
            start_dt: S25Util.date.dropTZString,
            end_dt: S25Util.date.dropTZString,
            last_mod_dt: S25Util.date.dropTZString,
            pubdate: S25Util.date.dropTZString,
            modified_since: S25Util.date.dropTZString,
        });
    }

    public static _getSearchCriteria(typeId: number, queryId: number) {
        return DataAccess.get(
            DataAccess.injectCaller(
                SEARCH_TYPE_ID_MAP[typeId].searchUrl + "?query_id=" + queryId,
                "SearchService.getSearchCriteria",
            ), //note: no prettyConv bc we may want to use this same model to put/post back later
        );
    }

    @Timeout
    @Cache({ targetName: "SearchService", immutable: true, expireTimeMs: 30000 })
    public static getSearchCriteria(typeId: number, queryId: number) {
        //get details of criteria used in specific search'
        return SearchService._getSearchCriteria(typeId, queryId).then(function (data) {
            let ret: any = {};
            data = S25Util.propertyGet(data, SEARCH_GET_MAP[typeId].searchCriteriaBase);
            if (data && data.search) {
                data = data.search;
                ret = S25Util.prettifyJson(data, SEARCH_GET_CUSTOM_KEY_MAP, { step_param: true, step: true });
                if (ret && ret.step) {
                    //only update relationship type for certain step type ids
                    for (let step of ret.step) {
                        if (step.step_param && parseInt(step.step_type_id) === 20) {
                            //if type 20, set itemId to relationship_type_id, same for itemName
                            for (let j = 0; j < step.step_param.length; j++) {
                                if (step.step_param[j].relationship_type_id) {
                                    step.step_param[j].itemId = step.step_param[j].relationship_type_id;
                                    step.step_param[j].itemName = step.step_param[j].relationship_type_name;
                                }
                            }
                        } else if (!step.step_param && parseInt(step.step_type_id) === 10) {
                            step.step_param = [{ step_param_nbr: 1, favorites: "T" }];
                        }

                        // No name = Private
                        if (QLUtil.isStepSearch(step) && !step.step_param[0].itemName)
                            step.step_param[0].isPrivate = true;
                    }
                }
            }
            if (SearchService.subjMapFun[typeId] && SearchService.subjMapFun[typeId].searchCriteriaGETTransform) {
                ret = SearchService.subjMapFun[typeId].searchCriteriaGETTransform(ret);
            }
            SearchService.stripAllTimezone(ret); //remove tz information from date fields
            return ret;
        });
    }

    /**
     * Validation
     */

    //advanced search validation
    public static getSearchErrors(lang: any, typeId: number, json: any) {
        let errors = [];
        let criteriaCount = (json && json.step && json.step.length) || 0;
        let browseLang = lang.div.application.browse;
        let baseStep = typeId * 100; //100, 200, 400, 600, 1000
        if (!json || !json.step || !json.step.length || json.step.length <= 0) {
            errors.push(browseLang.no_criteria); //invalid search: no steps
        } else if (
            json.step.filter(function (obj: any) {
                return (
                    obj &&
                    parseInt(obj.step_type_id) === baseStep + 50 &&
                    (!obj.step_param || (obj.step_param && obj.step_param.length === 0))
                );
            }).length > 0
        ) {
            errors.push(browseLang.unselected_custom_attribute); //invalid search: custom attribute exists but nothing selected
        } else if (
            json.step.filter(function (obj: any) {
                return (
                    obj &&
                    (!EMPTY_PARAM_ALLOWED[typeId] || !EMPTY_PARAM_ALLOWED[typeId][obj.step_type_id]) &&
                    (!obj.step_param ||
                        obj.step_param.length === 0 ||
                        (obj.step_param.length > 0 &&
                            !obj.step_param[0].itemId &&
                            [460, 461, 660, 661].indexOf(parseInt(obj.step_type_id)) > -1))
                );
            }).length > 0
        ) {
            //invalid search: some step param are empty (except params steps in emptyParamAllowed) [some like 460, loc relationship, need more than 1 param]
            errors.push(browseLang.unpopulated_criteria);
        } else if (
            json.step.filter(function (obj: any) {
                return (
                    obj &&
                    parseInt(obj.step_type_id) === 400 &&
                    parseInt(obj.step_param[0].min_capacity) >= 0 &&
                    parseInt(obj.step_param[0].max_capacity) >= 0 &&
                    parseInt(obj.step_param[0].min_capacity) > parseInt(obj.step_param[0].max_capacity)
                );
            }).length > 0
        ) {
            errors.push(browseLang.capacity_conflict); //invalid search: min cap > max cap
        } else if (
            json.step.filter(function (obj: any) {
                return (
                    obj &&
                    parseInt(obj.step_type_id) === 100 &&
                    S25Util.date.isValid(obj.step_param[0].start_dt) &&
                    S25Util.date.isValid(obj.step_param[0].end_dt) &&
                    S25Util.date.diffMinutes(obj.step_param[0].start_dt, obj.step_param[0].end_dt) < 0
                );
            }).length > 0
        ) {
            errors.push(browseLang.earliest_latest_conflict); //invalid search: start dt > end dt
        } else if (criteriaCount === 1 && [20].indexOf(parseInt(json.step[0].step_type_id)) > -1 && typeId === 1) {
            errors.push(browseLang.searches.other_criteria_first);
        } else if (
            criteriaCount === 2 &&
            [20].indexOf(parseInt(json.step[0].step_type_id)) > -1 &&
            [20].indexOf(parseInt(json.step[1].step_type_id)) > -1 &&
            typeId === 1
        ) {
            errors.push(browseLang.searches.other_criteria_first);
        }
        return errors;
    }

    public static getSearchesList(typeId: number, name?: string) {
        return DataAccess.get(
            DataAccess.injectCaller(
                SEARCH_TYPE_ID_MAP[typeId].listUrl + (name ? "&name=" + encodeURIComponent(name) : ""),
                "SearchService.getSearchesList",
            ),
        ).then(function (data) {
            return S25Util.prettifyJson(data, null, { item: true });
        });
    }

    @Timeout
    public static isSearchNameUnique(typeId: number, name: string) {
        //determine if search name is unique, ie, not already taken (note: there is no DB unique key on query name)            return DataAccess.get(
        return SearchService.getSearchesList(typeId, name).then((data) => {
            return !S25Util.valueFind(data, "name", name);
        });
    }

    /**
     *
     * @param showAlert - boolean
     * @param searchType - number 1,2 or 3 mapps to simple, predefined, advanced
     * @param searchModel
     * @param lang
     * @param subjectTypeId
     * @param defaultKeyword
     * @param returnMsg
     * @returns
     */
    public static isInvalidSearch(
        showAlert: boolean | string,
        searchType: number,
        searchModel: SearchCriteria.Model & { keyword?: { value?: string }; obj: {} },
        lang: any,
        subjectTypeId: number,
        defaultKeyword: string,
        returnMsg?: string,
    ) {
        //true/false, 1/2/3 {simple, predefined, advanced}
        showAlert = S25Util.toBool(showAlert);
        let errorMessage = "Your search has the following errors: ";
        let errors = [];
        let localSearchModel = S25Util.deepCopy(searchModel);
        if (
            searchType === 3 &&
            SearchService.subjMapFun[subjectTypeId] &&
            SearchService.subjMapFun[subjectTypeId].searchCriteriaPUTTransform
        ) {
            if (subjectTypeId === 10) {
                if (localSearchModel.step && localSearchModel.step.length) {
                    if (
                        !localSearchModel.step[0].states.cancelled &&
                        !localSearchModel.step[0].states.completed &&
                        !localSearchModel.step[0].states.denied &&
                        !localSearchModel.step[0].states.outstanding &&
                        !localSearchModel.step[0].states.unread
                    ) {
                        errors.push("(*) At least one state is required");
                    }
                    if (
                        !localSearchModel.step[0].types.assignment &&
                        !localSearchModel.step[0].types.authorization &&
                        !localSearchModel.step[0].types.fyi &&
                        !localSearchModel.step[0].types.todo &&
                        !localSearchModel.step[0].types.vcal
                    ) {
                        errors.push("(*) At least one type is required");
                    }
                } else {
                    errors.push("(*) Empty task search");
                }
            }

            localSearchModel =
                (errors.length === 0 &&
                    SearchService.subjMapFun[subjectTypeId].searchCriteriaPUTTransform(localSearchModel)) ||
                localSearchModel;
        }

        if (
            searchType === 1 &&
            (!searchModel.keyword.value ||
                searchModel.keyword.value.length <= 1 ||
                searchModel.keyword.value === defaultKeyword)
        ) {
            let hasCriteria = false;
            jSith.forEach(localSearchModel, function (_, bean) {
                //bean is a single-property obj, like "cabinetsBean": ...
                //we dont want to have to care about the bean name so we just loop here, but it is guaranteed a single O(1) iteration...
                jSith.forEach(bean, function (_, actualBean) {
                    if (actualBean) {
                        if (
                            (actualBean.selectedItems && actualBean.selectedItems.length > 0) ||
                            (actualBean.minBean && S25Util.isDefined(actualBean.minBean.model)) ||
                            (actualBean.maxBean && S25Util.isDefined(actualBean.maxBean.model))
                        ) {
                            hasCriteria = true;
                        }
                    }
                });
            });
            !hasCriteria &&
                searchModel.keyword.value !== defaultKeyword &&
                errors.push("(*) please search using at least 2 characters and/or at least one More Options criteria");
            !hasCriteria &&
                searchModel.keyword.value === defaultKeyword &&
                errors.push("(*) please include a search keyword and/or More Options criteria");
        } else if (searchType === 2 && (!localSearchModel || !localSearchModel.obj)) {
            errors.push("(*) missing predefined search");
        } else if (searchType === 3) {
            if (localSearchModel) {
                let advancedSearchErrors = SearchService.getSearchErrors(lang, subjectTypeId, localSearchModel);
                advancedSearchErrors.length > 0 &&
                    errors.push("(*) advanced search errors:\n\t" + advancedSearchErrors.join("\n\t"));
            } else {
                errors.push("(*) missing advanced search");
            }
        }

        if (errors.length > 0 && showAlert) {
            errorMessage += errors.join("\n");
            alert(errorMessage);
            return true; //true == has errors or is invalid
        } else if (returnMsg) {
            return errors.length > 0 && errorMessage + errors.join("\n");
        } else {
            return errors.length > 0; //true == has errors or is invalid
        }
    }

    /**
     * Search Write Section
     */

    @Timeout
    @Invalidate({
        serviceName: "SpaceService",
        methodName: "getSpacesBySearchQuery",
        validate: (args) => args[0] === 4,
    })
    @Invalidate({
        serviceName: "ResourceService",
        methodName: "getResourcesBySearchQuery",
        validate: (args) => args[0] === 6,
    })
    @Invalidate({ serviceName: "SearchService", methodName: "getSearches", objectFunc: (args) => [args[0]] })
    @Invalidate({
        serviceName: "SearchService",
        methodName: "getSearchCriteria",
        objectFunc: (args) => [args[0], args[2]],
    })
    public static putSearch(typeId: number, data: any, searchId: number) {
        return DataAccess.put(
            DataAccess.injectCaller(
                SEARCH_TYPE_ID_MAP[typeId].searchUrl + "?query_id=" + searchId,
                "SearchService.putSearch",
            ),
            data,
        );
    }

    @Timeout
    @Invalidate({
        serviceName: "SpaceService",
        methodName: "getSpacesBySearchQuery",
        validate: (args) => args[1] === 4,
    })
    @Invalidate({
        serviceName: "ResourceService",
        methodName: "getResourcesBySearchQuery",
        validate: (args) => args[1] === 6,
    })
    @Invalidate({ serviceName: "SearchService", methodName: "getSearches", objectFunc: (args) => [args[1]] })
    @Invalidate({
        serviceName: "SearchService",
        methodName: "getSearchCriteria",
        objectFunc: (args) => [args[0], args[1]],
    })
    public static deleteSearch(queryId: number, typeId: number) {
        return DataAccess.delete(
            DataAccess.injectCaller(
                SEARCH_TYPE_ID_MAP[typeId].searchUrl + "?query_id=" + queryId,
                "SearchService.deleteSearch",
            ),
        );
    }

    /*
     * Misc Search Service section
     */

    public static fromUntilHelper(fromDtVal: any, untilDtVal: any) {
        return { ...SearchService.fromHelper(fromDtVal), ...SearchService.untilHelper(untilDtVal) };
    }

    public static fromHelper(fromDtVal: any) {
        let ret: any = {};
        if ((!/^[-\+]*\d+$/.test(fromDtVal) || ("" + fromDtVal).length === 8) && S25Util.date.isValid(fromDtVal)) {
            ret.from_dt_date = S25Util.date.parseDropTZ(fromDtVal);
            ret.from_dt_type = "date";
            ret.from_dt_num = 0;
            ret.from_dt = ret.from_dt_date;
        } else {
            ret.from_dt_date = new Date();
            ret.from_dt_type = "number";
            ret.from_dt_num = parseInt(fromDtVal);
            ret.from_dt = ret.from_dt_num;
        }
        return ret;
    }

    public static untilHelper(untilDtVal: any) {
        let ret: any = {};
        if ((!/^[-\+]*\d+$/.test(untilDtVal) || ("" + untilDtVal).length === 8) && S25Util.date.isValid(untilDtVal)) {
            ret.until_dt_date = S25Util.date.parseDropTZ(untilDtVal);
            ret.until_dt_type = "date";
            ret.until_dt_num = 0;
            ret.until_dt = ret.until_dt_date;
        } else {
            ret.until_dt_date = new Date();
            ret.until_dt_type = "number";
            ret.until_dt_num = parseInt(untilDtVal);
            ret.until_dt = ret.until_dt_num;
        }
        return ret;
    }

    public static createDtHelper(fromDtVal: any) {
        let ret: any = {};
        if ((!/^[-\+]*\d+$/.test(fromDtVal) || ("" + fromDtVal).length === 8) && S25Util.date.isValid(fromDtVal)) {
            ret.from_dt_date = S25Util.date.parseDropTZ(fromDtVal);
            ret.from_dt_type = "date";
            ret.from_dt_num = 0;
        } else {
            ret.from_dt_date = new Date();
            ret.from_dt_type = "number";
            ret.from_dt_num = parseInt(fromDtVal) || 0;
        }
        return ret;
    }

    public static _shareSearchUsernames(queryId: number, usernames: string[], typeId: number) {
        if (usernames && usernames.length) {
            let contactList = ""; //form list of usernames for post
            jSith.forEach(usernames, function (_, username) {
                contactList += "\\;" + username;
            });
            contactList = contactList.substring(2); //chop first delim
            // let data = {search_user_list: {
            //     search_users: {
            //         search_id: queryId,
            //         user_list: contactList
            //     }
            // }};

            let data = //form post xml
                '<search_user_list xmlns="http://www.w3.org/1999/xhtml">' +
                "<search_users>" +
                "<search_id>" +
                queryId +
                "</search_id>" +
                "<user_list>" +
                contactList +
                "</user_list>" +
                "</search_users>" +
                "</search_user_list>";
            return DataAccess.post(
                DataAccess.injectCaller(SEARCH_TYPE_ID_MAP[typeId].copyUrl, "SearchService._shareSearchUsernames"),
                data,
            ); //post data
        } else {
            return jSith.reject({ msg: "No contacts with a valid username." });
        }
    }

    @Timeout
    public static shareSearchWithGroup(searchName: string, queryId: number, groupId: number, typeId: number) {
        return GroupService.getGroupUsernames(groupId).then(function (usernames) {
            SearchService._shareSearchUsernames(queryId, usernames, typeId);
        });
    }

    @Timeout
    public static shareSearch(searchName: string, queryId: number, contacts: any[], type: number) {
        //share search (copies search to another user) and also emails users and initiator a success email
        let usernames: string[] = [];
        if (contacts && S25Util.array.isArray(contacts)) {
            jSith.forEach(contacts, function (_, item) {
                let username = "";
                if (item && item.r25user && item.r25user.r25_username) {
                    username = S25Util.toStr(item.r25user.r25_username);
                } else if (item && item.web_users && S25Util.array.isArray(item.web_users)) {
                    for (let i = 0; i < item.web_users.length; i++) {
                        if (item.web_users[i] && item.web_users[i].web_username) {
                            username = S25Util.toStr(item.web_users[i].web_username);
                            break;
                        }
                    }
                }

                if (username && username.length > 0) {
                    //usernames are delimited by \;
                    usernames.push(username);
                }
            });

            return SearchService._shareSearchUsernames(queryId, usernames, type);
        }
    }

    @Timeout
    public static deleteSearchWithConf(queryId: number, queryName: string, objectType: number, callback: any) {
        let modalService = window.angBridge.$injector.get("s25ModalService");
        if (queryId) {
            let message =
                "<span>Are you sure you want to delete this search?</span><div class='ngVizTitle'>" +
                queryName +
                "</div>";
            let data = ModalService.dialogType("Yes No", {
                message: message,
                title: "Deletion Confirmation",
            });
            return modalService.modal("dialog", data).then(function () {
                if (data.answer === 1) {
                    return SearchService.deleteSearch(queryId, objectType).then(function () {
                        callback && callback();
                    });
                }
            });
        }
    }

    public static custAtrb: any = {
        handleRelationshipId: function (
            custAtrbType: string,
            reltionshipType: any,
            typeBase: any,
            itemId: string,
        ): any {
            if (custAtrbType === "B") {
                //handle boolean T vs F differences (we need different relationship type whereas the query engine does not
                if (parseInt(reltionshipType) === typeBase + 50) {
                    if (itemId === "T") {
                        return parseInt(reltionshipType) + 1000;
                    } else {
                        //on the way out, when saving, we'll adjust so the relationship type id and step type id are correct
                        return parseInt(reltionshipType) + 2000;
                    }
                }
            }
            return reltionshipType;
        },

        handleWild: function (custAtrbType: string, reltionshipType: string, typeBase: number, itemId: string): any {
            if (["R", "S"].indexOf(custAtrbType) > -1) {
                if (parseInt(reltionshipType) === typeBase + 58 && itemId) {
                    //contains search: cut off prepended % and appended %
                    if (itemId[0] === "%") {
                        itemId = itemId.substring(1);
                    }
                    if (itemId[itemId.length - 1] === "%") {
                        itemId = itemId.substring(0, itemId.length - 1);
                    }
                } else if (parseInt(reltionshipType) === typeBase + 53 && itemId) {
                    //starts with search: cut off appended %
                    if (itemId[itemId.length - 1] === "%") {
                        itemId = itemId.substring(0, itemId.length - 1);
                    }
                }
            }
            return itemId;
        },

        handleInit: function (item: any, typeBase: number) {
            if (item && item.step_param && item.step_param[0]) {
                let param = item.step_param[0];
                if (param && param.cust_atrb_type === "B") {
                    param.relationship_type_id = SearchService.custAtrb.handleRelationshipId(
                        param.cust_atrb_type,
                        param.relationship_type_id,
                        typeBase,
                        param.itemId,
                    );
                } else if (["R", "S"].indexOf(item.step_param[0].cust_atrb_type) > -1) {
                    param.itemId = SearchService.custAtrb.handleWild(
                        param.cust_atrb_type,
                        param.relationship_type_id,
                        typeBase,
                        param.itemId,
                    );
                }
            }
        },

        setValueOnParam: function (stepParam: any, typeBase: number) {
            if (stepParam && stepParam.cust_atrb_type === "B") {
                if (stepParam.relationship_type_id > 2000) {
                    stepParam.itemId = "F";
                } else if (stepParam.relationship_type_id > 1000) {
                    stepParam.itemId = "T";
                } else {
                    stepParam.itemId = "F";
                }
            } else if (
                stepParam &&
                (parseInt(stepParam.relationship_type_id) === typeBase + 56 ||
                    parseInt(stepParam.relationship_type_id) === typeBase + 57)
            ) {
                stepParam.previousItemId = stepParam.itemId; //save previous item id to set it back if they traverse out of exists/not-exists
                stepParam.itemId = ""; //no ngModel value for "exists" and "does not exist" queries...
            } else if (stepParam.previousItemId) {
                //only set if they switched to exists/not-exists and then back
                stepParam.itemId = stepParam.previousItemId;
                stepParam.previousItemId = null;
            }
        },

        setStepId: function (step: any) {
            let stepParam = step && step.step_param && step.step_param[0];
            if (stepParam && stepParam.relationship_type_id) {
                if (stepParam.relationship_type_id > 2000) {
                    step.step_type_id = stepParam.relationship_type_id - 2000;
                } else if (stepParam.relationship_type_id > 1000) {
                    step.step_type_id = stepParam.relationship_type_id - 1000;
                } else {
                    step.step_type_id = stepParam.relationship_type_id;
                }
            }
        },

        onSelect: function (item: any, typeBase: number) {
            let custAttr = S25Util.deepCopy(item.step_param[0]); //copy so that each entry is its own object (ie, ng model wont apply to all)
            if (
                custAttr &&
                custAttr.cust_atrb_type &&
                AdvancedSearchUtil.customAttrTypeMap[custAttr.cust_atrb_type] &&
                !custAttr.relationship_type_id
            ) {
                custAttr.relationship_type_id =
                    typeBase + AdvancedSearchUtil.customAttrTypeMap[custAttr.cust_atrb_type].relationship_type_id;
            }
            item.step_param[0] = custAttr;
            SearchService.custAtrb.setValueOnParam(custAttr);
            SearchService.custAtrb.setStepId(item);
        },

        relationshipTypeChanged: function (item: any, typeBase: number) {
            SearchService.custAtrb.setValueOnParam(item.step_param[0], typeBase);
            SearchService.custAtrb.setStepId(item);
        },
    };

    /*
     * Search Model Normalization and Conversion to json / Writing and Saving json to DB section
     */

    //map of functions per subject to run
    public static subjMapFun: any = {
        10: {
            searchCriteriaGETTransform: function (data: any) {
                return TaskService.taskSearchCriteriaGETTransform(data);
            }, //transforms api search criteria to another format used by adv search
            searchCriteriaPUTTransform: function (data: SearchCriteria.Model) {
                return TaskService.taskSearchCriteriaPUTTransform(data);
            },
        },
    };

    //queryId only required for searchType 2 (predef search), searchModel and origSearchModel only required for search type 3 (adv search)
    //searchModel is also required for type 1 search (simple)
    public static saveAs(
        searchType: number,
        subjId: number,
        isSaveExisting: boolean,
        queryId?: number,
        searchModel?: any,
        origSearchModel?: any,
        searchName?: string,
        noDelete?: boolean,
    ) {
        searchName = searchName || "";
        let getModelPromise: any,
            searchOptions: any,
            modalPromise: any,
            makePubOverride: any,
            makeFav: any,
            isNewSearch = !isSaveExisting;
        if (
            (searchType === 3 && searchModel && (origSearchModel || isNewSearch)) ||
            (searchType === 1 && searchModel)
        ) {
            getModelPromise = jSith.when();
        } else if (searchType === 2 && subjId && queryId) {
            getModelPromise = SearchService.getSearchCriteria(subjId, queryId).then(function (data) {
                searchModel = data;
                origSearchModel = S25Util.deepCopy(data); //might need orig search model if we are re-naming search
            });
        }

        return (
            getModelPromise &&
            getModelPromise.then(function () {
                if (searchType === 1) {
                    searchOptions = { typeId: subjId };
                } else {
                    searchName += isNewSearch && searchModel.query_id && searchName ? " (Copy)" : "";
                    searchOptions = {
                        searchName: searchName,
                        typeId: subjId,
                        saveExisting: !isNewSearch,
                    };
                }

                modalPromise = ModalService.modal("save-search", searchOptions).then(function () {
                    if (searchOptions.searchMeta) {
                        //if save search clicked, we get some user choices from searchMeta now
                        searchName = searchOptions.searchMeta.name;
                        makeFav = searchOptions.searchMeta.makeFav;
                        return true; //return from promise
                    } else {
                        return false;
                    }
                });

                return modalPromise.then(function (modalRet: any) {
                    if (modalRet) {
                        if (searchType === 3 || searchType === 2) {
                            return SearchService.putJsonSearch(
                                subjId,
                                searchModel,
                                origSearchModel,
                                isNewSearch,
                                searchName,
                                makePubOverride,
                                makeFav,
                                noDelete,
                            )
                                .then(function (ret) {
                                    return { putResp: ret, searchName: searchName, searchType: searchType };
                                })
                                .catch(function (ret: any) {
                                    S25Util.showError(ret);
                                    return false;
                                });
                        } else {
                            //searchType===1
                            return UserprefService.getContactId().then(function (contactId) {
                                return SearchService.saveSearch(
                                    subjId,
                                    searchModel,
                                    contactId,
                                    searchName,
                                    makePubOverride,
                                    makeFav,
                                )
                                    .then(function () {
                                        return true;
                                    })
                                    .catch(function (ret: any) {
                                        S25Util.showError(ret);
                                        return false;
                                    });
                            });
                        }
                    }
                });
            })
        );
    }

    //see s25SearchAdvancedStepTemplate in advanced-search-util.ts for template json examples by subject and step type id
    @Timeout
    public static putJsonSearch(
        typeId: number,
        json: any,
        origJson: any,
        isNewSearch: boolean,
        searchName: string,
        makePubOverride?: boolean,
        makeFav?: boolean,
        noDelete?: boolean,
    ) {
        let promise = isNewSearch ? SearchService.getNewSearchId(typeId) : jSith.when(json.query_id); //get new query id, if needed
        return promise.then(function (queryId) {
            queryId = S25Util.parseInt(queryId);
            json.query_id = queryId;
            return SearchService.formSearchJson(
                typeId,
                json,
                origJson,
                isNewSearch,
                searchName,
                makePubOverride,
                makeFav,
                noDelete,
            ).then(function (searchJson) {
                //form json
                return SearchService.putSearch(typeId, searchJson, S25Util.parseInt(queryId)).then(function (ret) {
                    //put search
                    return { queryId: S25Util.parseInt(queryId), ret: S25Util.prettifyJson(ret) }; //return query id and return json;
                });
            });
        });
    }

    public static orderSteps(json: any) {
        let steps = json.step;
        if (steps && steps.length) {
            let shallowestFromBottom = steps.length;
            for (let i = steps.length - 1; i >= 0; i--) {
                let stepTypeId = parseInt(steps[i].step_type_id);
                if (stepTypeId === 20) {
                    //step type 20 (has bound, has related) needs to be last
                    if (i < shallowestFromBottom - 1) {
                        let last = steps[shallowestFromBottom - 1];
                        let temp = S25Util.deepCopy(last);
                        steps[shallowestFromBottom - 1] = steps[i];
                        steps[i] = temp;
                    }
                    shallowestFromBottom--;
                }
            }
        }
    }

    //saved searches may be corrupted from past code; this attempts to cleanse them so the search will be de-corrupted and thus saving over will work
    public static cleanseOrigJson(origJson: any) {
        if (origJson && origJson.step) {
            for (let i = origJson.step.length - 1; i >= 0; i--) {
                if (origJson.step[i].step_param) {
                    for (let j = origJson.step[i].step_param.length - 1; j >= 0; j--) {
                        if (parseInt(origJson.step[i].step_param[j].contact_role_id) === 0) {
                            //remove 0 contact_role_id from step_param
                            delete origJson.step[i].step_param[j].contact_role_id;
                            delete origJson.step[i].step_param[j].contact_role_name;
                        }
                    }
                }
            }
        }
    }

    //see s25SearchAdvancedStepTemplate in advanced-search-util.ts for template json examples by subject and step type id
    //note that if using searchCriteriaBean, you must first convert to a step & step_param model, which is done here in: saveSearch, which saves the search too
    @Timeout
    public static formSearchJson(
        typeId: number,
        json: JsonModel,
        origJson: JsonModel,
        isNewSearch: boolean,
        searchName: string,
        makePubOverride?: boolean,
        makeFav?: boolean,
        noDelete?: boolean,
    ) {
        return S25Util.all({
            prefs: PreferenceService.getPreferences(["config_search_user"]),
            currentUsername: ContactService.getCurrentUsername(),
        }).then(function (resp) {
            let makePub = S25Util.coalesce(
                makePubOverride,
                (resp.prefs.config_search_user && resp.prefs.config_search_user.value) === resp.currentUsername,
            );
            let searchPromise = jSith.defer(),
                queryId = -1;
            json = S25Util.deepCopy(json); //copy search model

            if (SearchService.subjMapFun[typeId] && SearchService.subjMapFun[typeId].searchCriteriaPUTTransform) {
                json = json && SearchService.subjMapFun[typeId].searchCriteriaPUTTransform(json);
                origJson = origJson && SearchService.subjMapFun[typeId].searchCriteriaPUTTransform(origJson);
            }

            if (isNewSearch && !json.query_id) {
                SearchService.getNewSearchId(typeId).then(function (newSearchId) {
                    //get new search id
                    queryId = newSearchId;
                    searchPromise.resolve(); //resolve
                });
            } else {
                queryId = json.query_id;
                searchPromise.resolve(); //not new search, so resolve
            }

            //replace model values with map values (perform many data replacements, eg, convert date to string)
            let replaceValueMap = S25Util.deepCopy(SearchService.replaceValueMap); //copy replacement map
            replaceValueMap.status = "new"; //set status to new

            json = S25Util.replaceDeep(json, replaceValueMap); //perform many data replacements, eg, convert date to string
            origJson = origJson && S25Util.replaceDeep(origJson, replaceValueMap); //do the same for orig json to ensure it has the same format
            SearchService.cleanseOrigJson(origJson);

            //special contact id and contact name handling (move from step to each step param), piggy back step and param number setting too
            SearchService.handleContactIdAndSetNumbers(json);

            //some steps need special ordering (insane...)
            SearchService.orderSteps(json);
            origJson && SearchService.orderSteps(origJson);
            return searchPromise.promise.then(function () {
                //after resolve, format json for WS
                json.query_id = queryId;

                json.query_name = searchName; //set query name

                if (origJson && parseInt(origJson.query_type_id) === 3) {
                    json.query_type = "preference";
                    json.query_type_id = "3";
                } else {
                    json.query_type = S25Util.isUndefined(makePub) ? json.query_type : makePub ? "Public" : "Private"; //set public or private
                    json.query_type_id = S25Util.isUndefined(makePub) ? json.query_type_id : makePub ? "2" : "1"; //set public or private
                }
                json.favorite = S25Util.isUndefined(makeFav) ? json.favorite : makeFav ? "T" : "F"; //set favorite
                json.last_mod_user = resp.currentUsername; //set last mod user
                json.last_mod_dt = S25Util.date.toS25ISODateTimeStr(new Date()); //set last mod dt
                json.status = isNewSearch ? "new" : "mod"; //set search status
                json._href = json.href || SAVE_SEARCH_MAP[typeId].itemUrl + queryId; //set href to attribute href
                delete json.href; //delete tag (already an attribute now)
                // delete json.status; //delete tag (already an attribute now)
                if (!isNewSearch) {
                    //NO LONGER TRUE: noDelete is set to true for task searches, which do not require a separate deletion like normal searches do (API differences...)
                    let delStep = S25Util.deepCopy(origJson.step);
                    jSith.forEach(delStep, function (_, ds) {
                        //just in case status is not a property on steps, add it as del for orig search
                        ds.status = "del";
                    });
                    json.step = S25Util.replaceDeep(delStep, { status: "del" }).concat(json.step); //delete original steps
                }
                let rootJson: any = {}; //root object (json has no root)
                rootJson[SAVE_SEARCH_MAP[typeId].rootName] = {
                    //set root property based on compsubject and set other attributes
                    search: json,
                    _engine: "accl",
                    _pubdate: S25Util.date.toS25ISODateTimeStr(new Date()),
                };
                rootJson = SearchService.changeSearchJsonKeys(
                    rootJson,
                    "",
                    S25Util.deepCopy(SearchModelBackwardLookup[typeId]),
                    null,
                    SEARCH_MODEL_SKIP_LIST.list,
                ); //set r25 prefix, update itemId, itemName to subject names, remove tags from skip list
                return rootJson;
            });
        });
    }

    public static keywordModelToSteps(typeId: Item.Id, modelBean: any, contactName: string, contactId: number) {
        let hasCapacityBean = false,
            hasSelectedItems = false,
            usesKeyword = modelBean.keyword && modelBean.keyword.value && modelBean.keyword.value.length > 0;
        let steps = [];

        //map beans to step types, and get step template
        let beanNameMap = SearchCriteriaBeanNameMap[typeId],
            template = S25Util.deepCopy(AdvancedSearchUtil.s25SearchAdvancedStepTemplate[S25Const.itemId2Name[typeId]]);

        //for each search criteria bean object
        jSith.forEach(modelBean.searchCriteriaBean, function (key, obj) {
            //we will be forming a new step in each iteration
            let newStep: any,
                //any or all qualifier on a param step -- defaults to any, just like in getQueryString
                stepQualifier: any = { any: "1", all: "2" },
                keyNameMap = beanNameMap[key],
                //min/max values for capacity bean
                maxValue = (obj.maxBean && obj.maxBean.model) || 0,
                minValue = (obj.minBean && obj.minBean.model) || 0;
            stepQualifier = stepQualifier[(obj.matchingBean && obj.matchingBean.model) || "any"];

            //if min and max are equal and 0, then no capacity bean
            let isCapacityBean =
                key === "capacityBean" && !(parseInt(maxValue) === parseInt(minValue) && parseInt(maxValue) === 0);

            //if search criteria object has selected items (multiselect) or is the special capacity selector
            if ((obj.selectedItems && obj.selectedItems.length > 0) || isCapacityBean) {
                //then we have a new step!
                newStep = S25Util.deepCopy(template[keyNameMap.stepTypeId]);
                if (isCapacityBean) {
                    hasCapacityBean = true;
                    //sub type id: set some sub items to false so we can delete them
                    newStep.step_param.map(function (item: any) {
                        S25Util.merge(
                            item,
                            S25Util.deepCopy(template.subType[keyNameMap.stepTypeId][keyNameMap.subTypeId]),
                        );
                    });
                    newStep.step_param.map(S25Util.deleteFalseProperties); //delete false sub items
                    newStep.step_param[0].max_capacity =
                        obj.maxBean && !S25Util.isUndefined(obj.maxBean.model) && maxValue > 0 && obj.maxBean.model; //add max cap
                    newStep.step_param[0].min_capacity =
                        obj.minBean && !S25Util.isUndefined(obj.minBean.model) && minValue > 0 && obj.minBean.model; //add min cap
                } else {
                    hasSelectedItems = true;
                    if (typeId === 1 && key === "rolesBean") {
                        //adding "Your Role"
                        newStep.contact_role_id = obj.selectedItems[0].itemId;
                        newStep.contact_role_name = obj.selectedItems[0].itemName;
                        newStep.step_param.push({ itemName: contactName, itemId: contactId });
                    } else {
                        obj.selectedItems.forEach(function (obj: any) {
                            newStep.step_param.push({ itemName: obj.itemName, itemId: obj.itemId });
                        });
                    }
                    newStep.qualifier = stepQualifier;
                    newStep.qualifier_name = parseInt(newStep.qualifier) === 1 ? "Include Any" : "Include All";
                }
            }
            newStep && steps.push(newStep);
        });

        if (usesKeyword) {
            var stepTypeId = typeId * 100 + 45; //145, 445, etc (see STORY-3850)
            var newStep = S25Util.deepCopy(template[stepTypeId]);
            newStep.step_param[0].itemName = modelBean.keyword.value;
            newStep && steps.push(newStep);
        }

        return steps;
    }

    public static keywordModelToQueryModel(typeId: Item.Id, modelBean: any, contactName: string, contactId: number) {
        return {
            step: SearchService.keywordModelToSteps(typeId, modelBean, contactName, contactId),
            query_method: "all",
        };
    }

    //transform search criteria beans to the general step & param format - used by WS, then save
    public static saveSearch(
        typeId: number,
        modelBean: any,
        contactId: number,
        queryName?: string,
        makePubOverride?: boolean,
        makeFav?: boolean,
    ) {
        queryName = queryName && queryName.substring(0, 40);
        let isNewSearch = true;
        let hasCapacityBean = false,
            hasSelectedItems = false,
            usesKeyword = modelBean.keyword && modelBean.keyword.value && modelBean.keyword.value.length > 0;
        let searchObj: any = { step: [], query_method: "all" }, //search obj template
            keywordSearchObj: any = { step: [], query_method: "any" }; //keyword search obj template
        let beanNameMap = SearchCriteriaBeanNameMap[typeId],
            template = S25Util.deepCopy(AdvancedSearchUtil.s25SearchAdvancedStepTemplate[modelBean.subject.value]); //map beans to step types, and get step template
        jSith.forEach(modelBean.searchCriteriaBean, function (key, obj) {
            let newStep: any;
            //let stepQualifier: any = {any: "1", all: "2"}[obj.matchingBean && obj.matchingBean.model || "any"]; //any or all qualifier on a param step -- defaults to any, just like in getQueryString
            let stepQualifier: any = { any: "1", all: "2" };
            stepQualifier = stepQualifier[(obj.matchingBean && obj.matchingBean.model) || "any"]; //any or all qualifier on a param step -- defaults to any, just like in getQueryString
            let keyNameMap = beanNameMap[key],
                maxValue = (obj.maxBean && obj.maxBean.model) || 0,
                minValue = (obj.minBean && obj.minBean.model) || 0;
            let isCapacityBean =
                key === "capacityBean" && !(parseInt(maxValue) === parseInt(minValue) && parseInt(maxValue) === 0); //if min and max are equal and 0, then no capacity bean
            if ((obj.selectedItems && obj.selectedItems.length > 0) || isCapacityBean) {
                newStep = S25Util.deepCopy(template[keyNameMap.stepTypeId]);
                if (isCapacityBean) {
                    hasCapacityBean = true;
                    newStep.step_param.map(function (item: any) {
                        S25Util.merge(
                            item,
                            S25Util.deepCopy(template.subType[keyNameMap.stepTypeId][keyNameMap.subTypeId]),
                        );
                    }); //sub type id: set some sub items to false so we can delete them
                    newStep.step_param.map(S25Util.deleteFalseProperties); //delete false sub items
                    newStep.step_param[0].max_capacity =
                        obj.maxBean && !S25Util.isUndefined(obj.maxBean.model) && maxValue > 0 && obj.maxBean.model; //add max cap
                    newStep.step_param[0].min_capacity =
                        obj.minBean && !S25Util.isUndefined(obj.minBean.model) && minValue > 0 && obj.minBean.model; //add min cap
                } else {
                    hasSelectedItems = true;
                    obj.selectedItems.forEach(function (obj: any) {
                        newStep.step_param.push({ itemName: obj.itemName, itemId: obj.itemId });
                    });
                    newStep.qualifier = stepQualifier;
                    newStep.qualifier_name = parseInt(newStep.qualifier) === 1 ? "Include Any" : "Include All";
                }
            }
            newStep && searchObj.step.push(newStep);
        });

        if (usesKeyword) {
            let stepTypeId = typeId * 100; //100, 400, etc
            let newStep1 = S25Util.deepCopy(template[stepTypeId]),
                newStep2 = S25Util.deepCopy(template[stepTypeId]),
                newStep3 = S25Util.deepCopy(template[stepTypeId]);

            //create new steps for keyword search, such as name, title, reference (for event, 100), or name and formal name (space, 400). Resource (600) only has name
            newStep1.step_param.map(function (item: any) {
                S25Util.merge(item, S25Util.deepCopy(template.subType[stepTypeId][1]));
            });
            stepTypeId !== 600 &&
                newStep2.step_param.map(function (item: any) {
                    S25Util.merge(item, S25Util.deepCopy(template.subType[stepTypeId][2]));
                });
            stepTypeId === 100 &&
                newStep3.step_param.map(function (item: any) {
                    S25Util.merge(item, S25Util.deepCopy(template.subType[stepTypeId][3]));
                });

            newStep1.step_param.map(S25Util.deleteFalseProperties);
            stepTypeId !== 600 && newStep2.step_param.map(S25Util.deleteFalseProperties);
            stepTypeId === 100 && newStep3.step_param.map(S25Util.deleteFalseProperties);

            newStep1.step_param[0].itemName = modelBean.keyword.value;
            newStep1.step_param[0].searchMethod = "contains";
            if (stepTypeId !== 600) {
                newStep2.step_param[0].formal_name = modelBean.keyword.value;
            }
            if (stepTypeId !== 600) {
                newStep2.step_param[0].searchMethod = "contains";
            }
            if (stepTypeId === 100) {
                newStep3.step_param[0].reference_number = modelBean.keyword.value;
            }

            keywordSearchObj.step.push(newStep1);
            stepTypeId !== 600 && keywordSearchObj.step.push(newStep2);
            stepTypeId === 100 && keywordSearchObj.step.push(newStep3);
        }

        if (usesKeyword && !hasCapacityBean && !hasSelectedItems) {
            //put plain keywordSearchObj
            return SearchService.putJsonSearch(
                typeId,
                keywordSearchObj,
                null,
                isNewSearch,
                queryName,
                makePubOverride,
                makeFav,
            ).then(function (ret) {
                return ret;
            });
        } else if (usesKeyword && searchObj.step.length > 0) {
            //put keywordSearchObj, then searchObj
            let keyWordSearchName = "Keyword Search: " + queryName.substring(0, 24);
            return SearchService.putJsonSearch(
                typeId,
                keywordSearchObj,
                null,
                isNewSearch,
                keyWordSearchName,
                makePubOverride,
                makeFav,
            ).then(function (ret1) {
                let newStep = S25Util.deepCopy(template[SAVE_SEARCH_MAP[typeId].itemSearchTypeId]);
                newStep.step_param.push({ itemName: keyWordSearchName, itemId: ret1.queryId });
                searchObj.step.push(newStep);
                return SearchService.putJsonSearch(
                    typeId,
                    searchObj,
                    null,
                    isNewSearch,
                    queryName,
                    makePubOverride,
                    makeFav,
                ).then(function (ret2) {
                    return ret2;
                });
            });
        } else if (searchObj.step.length > 0) {
            //put searchObj (no keyword)
            return SearchService.putJsonSearch(
                typeId,
                searchObj,
                null,
                isNewSearch,
                queryName,
                makePubOverride,
                makeFav,
            ).then(function (ret) {
                return ret;
            });
        }
    }

    //contact id moved from step_param to step for angular, we move back down from step to step param before putting
    public static handleContactIdAndSetNumbers(json: any, includeZeroRole?: boolean) {
        if (json && json.step) {
            for (let i = json.step.length - 1; i >= 0; i--) {
                json.step[i].step_number = i + 1; //also set step number as convenience
                if (json.step[i].step_param) {
                    for (let j = json.step[i].step_param.length - 1; j >= 0; j--) {
                        json.step[i].step_param[j].step_param_nbr = j + 1; //also set step param nbr as convenience
                        //if contact_role_id is 0, it means 'Any', which the API interprets via absence / undef
                        //so when PUTting, includeZeroRole is NOT set so it is falsy and 0's are excluded
                        //but SeriesQL wants to include it so it can pass values b/t Design and QL views so it sets includeZeroRole to true
                        if (
                            S25Util.isDefined(json.step[i].contact_role_id) &&
                            (includeZeroRole || parseInt(json.step[i].contact_role_id) !== 0)
                        ) {
                            json.step[i].step_param[j].contact_role_id = json.step[i].contact_role_id;
                            json.step[i].step_param[j].contact_role_name = json.step[i].contact_role_name;
                        }
                    }
                }
                if (S25Util.isDefined(json.step[i].contact_role_id)) {
                    delete json.step[i].contact_role_id;
                    delete json.step[i].contact_role_name;
                }
            }
        }
    }

    //add r25 prefix, and transform itemId to event_id, etc, also skip certain properties
    public static changeSearchJsonKeys(json: any, prefix: string, fullKeyMap: any, currKeyMap: any, skipList: any) {
        let retJson: any = {};
        let keyMap = (fullKeyMap && json && json.step_type_id && fullKeyMap[json.step_type_id]) || currKeyMap;
        jSith.forEach(json, function (key, obj) {
            if (!skipList || skipList.indexOf(key) < 0) {
                let newKey = "" + ((keyMap && keyMap[key]) || key);
                newKey = ((newKey.indexOf("_") !== 0 && prefix) || "") + newKey;
                retJson[newKey] = json[key];
                if (S25Util.array.isArray(json[key])) {
                    for (let i = 0; i < json[key].length; i++) {
                        retJson[newKey][i] = SearchService.changeSearchJsonKeys(
                            json[key][i],
                            prefix,
                            fullKeyMap,
                            keyMap,
                            skipList,
                        );
                    }
                } else if (typeof json[key] === "object") {
                    retJson[newKey] = SearchService.changeSearchJsonKeys(
                        json[key],
                        prefix,
                        fullKeyMap,
                        keyMap,
                        skipList,
                    );
                }
            }
        });
        return retJson;
    }

    //note: SeriesQL may call these on params BEFORE passing to SearchService to save so these should ALL be idempotent
    public static replaceValueMap: any = {
        //map used to transform certain search property values before putting
        start_dt: S25Util.date.toS25ISODateStrStartOfDay,
        end_dt: S25Util.date.toS25ISODateStrEndOfDay,
        modified_since: S25Util.date.toS25ISODateTimeStr,
        occurrence_start_dt: S25Util.date.hourMinuteString,
        occurrence_end_dt: S25Util.date.hourMinuteString,
        created_on_or_after: S25Util.date.toS25ISODateTimeStr,
        start_time: function (value: any, parent: any) {
            if (
                parent &&
                parent.from_dt &&
                S25Util.date.isDate(parent.from_dt) &&
                (S25Util.isUndefined(value) || S25Util.date.toS25ISOTimeStr(value) === "00:00:00")
            ) {
                return S25Util.date.toS25ISOTimeStr(parent.from_dt);
            } else {
                return S25Util.date.toS25ISOTimeStr(value) || "00:00:00";
            }
        },
        relationship_type_id: function (value: any) {
            return value > 2000 ? value - 2000 : value > 1000 ? value - 1000 : value;
        },
        monday: function (bool: boolean) {
            return typeof bool === "boolean" ? (bool && "T") || "F" : bool;
        },
        tuesday: function (bool: boolean) {
            return typeof bool === "boolean" ? (bool && "T") || "F" : bool;
        },
        wednesday: function (bool: boolean) {
            return typeof bool === "boolean" ? (bool && "T") || "F" : bool;
        },
        thursday: function (bool: boolean) {
            return typeof bool === "boolean" ? (bool && "T") || "F" : bool;
        },
        friday: function (bool: boolean) {
            return typeof bool === "boolean" ? (bool && "T") || "F" : bool;
        },
        saturday: function (bool: boolean) {
            return typeof bool === "boolean" ? (bool && "T") || "F" : bool;
        },
        sunday: function (bool: boolean) {
            return typeof bool === "boolean" ? (bool && "T") || "F" : bool;
        },
        from_dt: function (value: any, parent: any) {
            if (parent) {
                if (parent.from_dt_type === "date" && S25Util.isDefined(parent.from_dt_date)) {
                    return S25Util.date.toS25ISODateStrStartOfDay(parent.from_dt_date);
                } else if (parent.from_dt_type === "number" && S25Util.isDefined(parent.from_dt_num)) {
                    return (parent.from_dt_num >= 0 ? "+" : "") + parent.from_dt_num;
                } else if (S25Util.isDefined(parent.start_time)) {
                    let createDtModel = SearchService.createDtHelper(parent.from_dt);
                    value = createDtModel.from_dt_date && S25Util.date.toS25ISODateStr(createDtModel.from_dt_date);
                    if (createDtModel.from_dt_type === "number") {
                        value = (createDtModel.from_dt_num >= 0 ? "+" : "") + createDtModel.from_dt_num;
                    }
                    return value;
                } else {
                    let fromUntilModel = SearchService.fromUntilHelper(parent.from_dt, parent.until_dt);
                    value =
                        fromUntilModel.from_dt_date &&
                        S25Util.date.toS25ISODateStrStartOfDay(fromUntilModel.from_dt_date);
                    if (fromUntilModel.from_dt_type === "number") {
                        value = (fromUntilModel.from_dt_num >= 0 ? "+" : "") + fromUntilModel.from_dt_num;
                    }
                    return value;
                }
            }
        },
        until_dt: function (value: any, parent: any) {
            if (parent) {
                if (parent.until_dt_type === "date" && S25Util.isDefined(parent.until_dt_date)) {
                    return S25Util.date.toS25ISODateStrEndOfDay(parent.until_dt_date);
                } else if (parent.until_dt_type === "number" && S25Util.isDefined(parent.until_dt_num)) {
                    return (parent.until_dt_num >= 0 ? "+" : "") + parent.until_dt_num;
                } else {
                    let fromUntilModel = SearchService.fromUntilHelper(parent.from_dt, parent.until_dt);
                    value =
                        fromUntilModel.until_dt_date &&
                        S25Util.date.toS25ISODateStrEndOfDay(fromUntilModel.until_dt_date);
                    if (fromUntilModel.until_dt_type === "number") {
                        value = (fromUntilModel.until_dt_num >= 0 ? "+" : "") + fromUntilModel.until_dt_num;
                    }
                    return value;
                }
            }
        },
        itemId: function (value: any, parent: any) {
            if (S25Util.date.isDate(value)) {
                if (parent && parent.cust_atrb_type === "T") {
                    return S25Util.date.toS25ISOTimeStr(value);
                } else if (parent && parent.cust_atrb_type === "D") {
                    return S25Util.date.toS25ISODateStrStartOfDay(value);
                } else if (parent && parent.cust_atrb_type === "E") {
                    return S25Util.date.toS25ISODateTimeStr(value);
                } else {
                    return S25Util.date.toS25ISODateTimeStr(value);
                }
            } else {
                return value;
            }
        },
        status: "est",
    };

    /*
     * Permissions Section
     */
    @Timeout
    public static getSearchTypePerms() {
        var searchPerms: any = { 1: {}, 4: {}, 2: {}, 6: {}, 10: {}, 3: {} };
        return UserprefService.getLoggedIn().then(function (isLoggedIn) {
            return FlsService.getFls().then(function (fls) {
                searchPerms[1].simple =
                    (["F", "R", "C"].indexOf(fls.EVENT_EVS) > -1 || fls.EVENT_PERM === "F") && fls.EVENT_SEARCH !== "N";
                searchPerms[4].simple =
                    (["F", "R", "C"].indexOf(fls.SPACE_LIST) > -1 || fls.SPACE_PERM === "F") &&
                    fls.SPACE_SEARCH !== "N";
                searchPerms[2].simple =
                    (["F", "R", "C"].indexOf(fls.CU_ACCOUNT) > -1 || fls.CU_PERM === "F") && fls.ACCOUNT_SEARCH !== "N";
                searchPerms[6].simple =
                    (["F", "R", "C"].indexOf(fls.RESOURCE_LIST) > -1 || fls.RESOURCE_PERM === "F") &&
                    fls.RESOURCE_SEARCH !== "N";
                searchPerms[3].simple = ["F"].indexOf(fls.CU_CONTACT) > -1;
                searchPerms[10].simple = false; //there is no simple search for tasks... ['F','R','C'].indexOf(fls.TASK_LIST)>-1;

                searchPerms[1].def = searchPerms[1].simple;
                searchPerms[4].def = searchPerms[4].simple;
                searchPerms[2].def = searchPerms[2].simple;
                searchPerms[6].def = searchPerms[6].simple;
                searchPerms[3].def = searchPerms[3].simple;
                searchPerms[10].def = fls.TASK_LIST !== "N";

                if (!isLoggedIn) {
                    searchPerms[1].adv = false;
                    searchPerms[4].adv = false;
                    searchPerms[2].adv = false;
                    searchPerms[6].adv = false;
                    searchPerms[10].adv = false;
                    searchPerms[3].adv = false;
                } else {
                    searchPerms[1].adv = searchPerms[1].def && fls.EVENT_SEARCH === "F";
                    searchPerms[4].adv = searchPerms[4].def && fls.SPACE_SEARCH === "F";
                    searchPerms[2].adv = searchPerms[2].def && fls.ACCOUNT_SEARCH === "F";
                    searchPerms[6].adv = searchPerms[6].def && fls.RESOURCE_SEARCH === "F";
                    searchPerms[10].adv = ["F"].indexOf(fls.TASK_LIST) > -1 && searchPerms[10].def;
                    searchPerms[3].adv = false;
                }

                return searchPerms;
            });
        });
    }

    @Timeout
    public static getButtonPerms(isOwner: boolean) {
        let subjectFlsSearchMap: any = {
            1: "EVENT_SEARCH",
            2: "ACCOUNT_SEARCH",
            4: "SPACE_SEARCH",
            6: "RESOURCE_SEARCH",
            10: "TASK_LIST",
        };
        let buttonPerms: any = { 1: {}, 4: {}, 2: {}, 6: {}, 10: {}, 3: {} };
        return UserprefService.getLoggedIn().then(function (isLoggedIn) {
            if (!isLoggedIn) {
                for (let i = 1; i <= 10; i++) {
                    if ([1, 2, 3, 4, 6, 10].indexOf(i) === -1) {
                        continue;
                    }
                    buttonPerms[i].edit = false;
                    buttonPerms[i].save = false;
                    buttonPerms[i].saveAs = false;
                    buttonPerms[i].saveOrSaveAs = false;
                    buttonPerms[i].rename = false;
                    buttonPerms[i].share = false;
                    buttonPerms[i].delete = false;
                    buttonPerms[i].publish = false;
                    buttonPerms[i].bulkEdit = false;
                    buttonPerms[i].runReport = false;
                }
                return buttonPerms;
            } else {
                return S25Util.all({
                    fls: FlsService.getFls(),
                    groupId: UserprefService.getGroupId(),
                }).then(function (resp) {
                    let fls = resp.fls;
                    for (let i = 1; i <= 10; i++) {
                        if ([1, 2, 3, 4, 6, 10].indexOf(i) === -1) {
                            continue;
                        }

                        //note: contact (3) has no rights to any of these as of yet
                        let subjectSearch = subjectFlsSearchMap[i];

                        buttonPerms[i].create =
                            (i === 2 && fls.CU_ACCOUNT === "F") ||
                            (i === 3 && fls.CU_CONTACT === "F") ||
                            (i === 4 && fls.SPACE_LIST === "F") ||
                            (i === 6 && fls.RESOURCE_LIST === "F");
                        buttonPerms[i].edit =
                            i !== 3 && ["F", "C"].indexOf(fls[subjectSearch]) > -1 && S25Util.isTruthy(isOwner);
                        buttonPerms[i].save =
                            i !== 3 && ["F", "C"].indexOf(fls[subjectSearch]) > -1 && S25Util.isTruthy(isOwner);
                        buttonPerms[i].saveAs = i !== 3 && ["F", "C"].indexOf(fls[subjectSearch]) > -1;
                        buttonPerms[i].saveOrSaveAs = buttonPerms[i].save || buttonPerms[i].saveAs;
                        buttonPerms[i].rename =
                            i !== 3 && ["F", "C"].indexOf(fls[subjectSearch]) > -1 && S25Util.isTruthy(isOwner);
                        buttonPerms[i].share =
                            i !== 3 &&
                            ["R", "C", "F"].indexOf(fls.SECURITY) > -1 &&
                            ["R", "C", "F"].indexOf(fls.CU_CONTACT) > -1;
                        buttonPerms[i].delete =
                            i !== 3 && ["F", "C"].indexOf(fls[subjectSearch]) > -1 && S25Util.isTruthy(isOwner);
                        buttonPerms[i].publish =
                            i !== 3 && fls[subjectSearch] === "F" && fls.VCAL_PUB === "F" && [1, 4].indexOf(i) > -1;
                        buttonPerms[i].bulkEdit =
                            (i === 1 && fls.WEBSECURITY === "F") ||
                            (i === 4 && ["F", "C"].indexOf(fls.SPACE_LIST) > -1) ||
                            (i === 2 && ["F", "C"].indexOf(fls.CU_ACCOUNT) > -1) ||
                            (i === 6 && ["F", "C"].indexOf(fls.RESOURCE_LIST) > -1) ||
                            (i === 10 && ["F", "C"].indexOf(fls.TASK_LIST) > -1);

                        buttonPerms[i].runReport = i !== 3 && i != 10 && fls.REP_LIST !== "N"; //Report ols is checked in s25sql-search.js
                    }
                    return buttonPerms;
                });
            }
        });
    }

    @Timeout
    public static getSubjectOptionsByPerms() {
        //return subjectOptions all at once (it is one-time bound)
        return SearchService.getSearchTypePerms().then(function (searchPerms) {
            var subjectOptionsArr = [];
            (searchPerms[1].adv || searchPerms[1].def || searchPerms[1].simple) &&
                subjectOptionsArr.push({ id: 1, name: "event" });
            (searchPerms[4].adv || searchPerms[4].def || searchPerms[4].simple) &&
                subjectOptionsArr.push({ id: 4, name: "location" });
            (searchPerms[2].adv || searchPerms[2].def || searchPerms[2].simple) &&
                subjectOptionsArr.push({ id: 2, name: "organization" });
            (searchPerms[6].adv || searchPerms[6].def || searchPerms[6].simple) &&
                subjectOptionsArr.push({ id: 6, name: "resource" });
            (searchPerms[10].adv || searchPerms[10].def || searchPerms[10].simple) &&
                subjectOptionsArr.push({ id: 10, name: "task" });
            (searchPerms[3].adv || searchPerms[3].def || searchPerms[3].simple) &&
                subjectOptionsArr.push({ id: 3, name: "contact" });
            return subjectOptionsArr;
        });
    }

    //migrated from: /usr/local/25live/hybrid/v26.0/xslt/25live/s25-event-search/search_criteria.xsl (and space-search, etc)
    @Timeout
    public static isSearchReadOnlyByPerms(searchModel: any, compsubject: string) {
        let isDefined = function (obj: any) {
            return !S25Util.isUndefined(obj);
        };
        return FlsService.getFls().then(function (fls) {
            if (compsubject === "event") {
                return (
                    S25Util.json
                        .jpath(searchModel, "//step[step_type_id='141']/step_param/date_pattern")
                        .filter(isDefined).length > 0 ||
                    S25Util.json
                        .jpath(searchModel, "//step[step_type_id='141']/step_param/relationship_type_id")
                        .filter(isDefined).length > 0 ||
                    S25Util.json.jpath(searchModel, "//step/step_param[cust_atrb_type='L']").filter(isDefined).length >
                        0 ||
                    S25Util.json
                        .jpath(searchModel, "//step[step_type_id='20' and step_param[related_event_type_id = '10']]")
                        .filter(isDefined).length > 0 ||
                    S25Util.json.jpath(searchModel, "//step[step_type_id='170']").filter(isDefined).length > 0 ||
                    S25Util.json.jpath(searchModel, "//step[step_type_id='171']").filter(isDefined).length > 0 ||
                    S25Util.json.jpath(searchModel, "//step[step_type_id='172']").filter(isDefined).length > 0 ||
                    S25Util.json.jpath(searchModel, "//step[step_type_id='173']").filter(isDefined).length > 0 ||
                    S25Util.json.jpath(searchModel, "//step[step_type_id='106']").filter(isDefined).length > 0 ||
                    S25Util.json.jpath(searchModel, "//step[step_type_id='180']").filter(isDefined).length > 0 ||
                    S25Util.json.jpath(searchModel, "//step[step_type_id='181']").filter(isDefined).length > 0 ||
                    S25Util.json
                        .jpath(searchModel, "//step[step_type_id='107']/step_param[not(itemName)]")
                        .filter(isDefined).length > 0 ||
                    S25Util.json
                        .jpath(searchModel, "//step[step_type_id='110']/step_param[not(itemName)]")
                        .filter(isDefined).length > 0 ||
                    S25Util.json
                        .jpath(searchModel, "//step[step_type_id='163']/step_param[not(itemName)]")
                        .filter(isDefined).length > 0 ||
                    S25Util.json
                        .jpath(searchModel, "//step[step_type_id='165']/step_param[not(itemName)]")
                        .filter(isDefined).length > 0 ||
                    S25Util.json
                        .jpath(searchModel, "//step[step_type_id='168']/step_param[not(itemName)]")
                        .filter(isDefined).length > 0 ||
                    (S25Util.json.jpath(searchModel, "//step[step_type_id='105']").filter(isDefined).length > 0 &&
                        fls.EVENT_CAB === "N") ||
                    (S25Util.json.jpath(searchModel, "//step[step_type_id='111']").filter(isDefined).length > 0 &&
                        fls.CU_CONTACT === "N") ||
                    (S25Util.json.jpath(searchModel, "//step[step_type_id='162']").filter(isDefined).length > 0 &&
                        (fls.SPACE_LIST === "N" || fls.SPACE_SEARCH === "N")) ||
                    (S25Util.json.jpath(searchModel, "//step[step_type_id='167']").filter(isDefined).length > 0 &&
                        (fls.RESOURCE_LIST === "N" || fls.RESOURCE_SEARCH === "N")) ||
                    (S25Util.json.jpath(searchModel, "//step[step_type_id='113']").filter(isDefined).length > 0 &&
                        (fls.CU_ACCOUNT === "N" || fls.ACCOUNT_SEARCH === "N")) ||
                    (S25Util.json.jpath(searchModel, "//step/step_param[cust_atrb_type='4']").filter(isDefined).length >
                        0 &&
                        (fls.SPACE_LIST === "N" || fls.SPACE_SEARCH === "N")) ||
                    (S25Util.json.jpath(searchModel, "//step/step_param[cust_atrb_type='6']").filter(isDefined).length >
                        0 &&
                        (fls.RESOURCE_LIST === "N" || fls.RESOURCE_SEARCH === "N")) ||
                    (S25Util.json.jpath(searchModel, "//step/step_param[cust_atrb_type='2']").filter(isDefined).length >
                        0 &&
                        (fls.CU_ACCOUNT === "N" || fls.ACCOUNT_SEARCH === "N")) ||
                    (S25Util.json.jpath(searchModel, "//step/step_param[cust_atrb_type='3']").filter(isDefined).length >
                        0 &&
                        fls.CU_CONTACT === "N")
                );
            } else if (compsubject === "location") {
                return (
                    S25Util.json.jpath(searchModel, "//step/step_param[cust_atrb_type='L']").filter(isDefined).length >
                        0 ||
                    S25Util.json
                        .jpath(searchModel, "//step[step_type_id='471']/step_param[not(itemName)]")
                        .filter(isDefined).length > 0 ||
                    S25Util.json
                        .jpath(searchModel, "//step[step_type_id='407']/step_param[not(itemName)]")
                        .filter(isDefined).length > 0 ||
                    (S25Util.json.jpath(searchModel, "//step[step_type_id='471']").filter(isDefined).length > 0 &&
                        fls.EVENT_SEARCH === "N") ||
                    (S25Util.json.jpath(searchModel, "//step/step_param[cust_atrb_type='6']").filter(isDefined).length >
                        0 &&
                        (fls.RESOURCE_LIST === "N" || fls.RESOURCE_SEARCH === "N")) ||
                    (S25Util.json.jpath(searchModel, "//step/step_param[cust_atrb_type='2']").filter(isDefined).length >
                        0 &&
                        (fls.CU_ACCOUNT === "N" || fls.ACCOUNT_SEARCH === "N")) ||
                    (S25Util.json.jpath(searchModel, "//step/step_param[cust_atrb_type='3']").filter(isDefined).length >
                        0 &&
                        fls.CU_CONTACT === "N")
                );
            } else if (compsubject === "resource") {
                return (
                    S25Util.json.jpath(searchModel, "//step/step_param[cust_atrb_type='L']").filter(isDefined).length >
                        0 ||
                    S25Util.json
                        .jpath(searchModel, "//step[step_type_id='671']/step_param[not(itemName)]")
                        .filter(isDefined).length > 0 ||
                    S25Util.json.jpath(searchModel, "//step[step_type_id='607']/step_param[not(itemName)]").length >
                        0 ||
                    (S25Util.json.jpath(searchModel, "//step[step_type_id='671']").filter(isDefined).length > 0 &&
                        fls.EVENT_SEARCH === "N") ||
                    (S25Util.json.jpath(searchModel, "//step/step_param[cust_atrb_type='4']").filter(isDefined).length >
                        0 &&
                        (fls.SPACE_LIST === "N" || fls.SPACE_SEARCH === "N")) ||
                    (S25Util.json.jpath(searchModel, "//step/step_param[cust_atrb_type='2']").filter(isDefined).length >
                        0 &&
                        (fls.CU_ACCOUNT === "N" || fls.ACCOUNT_SEARCH === "N")) ||
                    (S25Util.json.jpath(searchModel, "//step/step_param[cust_atrb_type='3']").filter(isDefined).length >
                        0 &&
                        fls.CU_CONTACT === "N")
                );
            } else if (compsubject === "organization") {
                return (
                    S25Util.json.jpath(searchModel, "//step/step_param[cust_atrb_type='L']").filter(isDefined).length >
                        0 ||
                    S25Util.json
                        .jpath(searchModel, "//step[step_type_id='271']/step_param[not(itemName)]")
                        .filter(isDefined).length > 0 ||
                    (S25Util.json.jpath(searchModel, "//step[step_type_id='271']").filter(isDefined).length > 0 &&
                        fls.EVENT_SEARCH === "N") ||
                    (S25Util.json.jpath(searchModel, "//step/step_param[cust_atrb_type='4']").filter(isDefined).length >
                        0 &&
                        (fls.SPACE_LIST === "N" || fls.SPACE_SEARCH === "N")) ||
                    (S25Util.json.jpath(searchModel, "//step/step_param[cust_atrb_type='2']").filter(isDefined).length >
                        0 &&
                        (fls.CU_ACCOUNT === "N" || fls.ACCOUNT_SEARCH === "N")) ||
                    (S25Util.json.jpath(searchModel, "//step/step_param[cust_atrb_type='3']").filter(isDefined).length >
                        0 &&
                        fls.CU_CONTACT === "N")
                );
            } else if (compsubject === "task" || compsubject === "contact") {
                return false;
            } else {
                return false;
            }
        });
    }

    @Timeout
    public static async getFullSearchCriteria(itemType: Item.Id, queryId: number) {
        const searches: any = {};
        const model = await this.getTempSearches(itemType, queryId, searches);
        return { model, searches };
    }

    @Timeout
    public static async getTempSearches(
        itemType: Item.Id,
        queryId: number,
        searches: any = {},
    ): Promise<ReturnType<typeof SearchService.getSearchCriteria>> {
        const search = await SearchService.getSearchCriteria(itemType, queryId);
        const tempSearches: Promise<void>[] = [];
        for (let step of search.step) {
            if (!QLUtil.isStepTemp(step)) continue;
            // This is a temp search, we have to retrieve it
            const tempPromise = this.getTempSearches(itemType, step.step_param[0].itemId, searches)
                .then((search) => {
                    searches[step.step_param[0].itemName] = search;
                })
                .catch((error: any) => {
                    if (error.data.results.info.msg_id !== "EV_I_NOPERM") throw error;
                    // No perm = private
                    step.step_param[0].itemName = "Private";
                    step.step_param[0].isPrivate = true;
                });
            tempSearches.push(tempPromise);
        }
        await Promise.all(tempSearches);
        return search;
    }

    public static getTempSearchesInModel(model: SearchCriteria.Model) {
        const steps =
            model.step && model.step.filter((step) => step.step_type_name?.toLowerCase().indexOf("search") > -1);
        return S25Util.propertyGetAll(steps, "itemName").filter((name) => name.startsWith?.("temp_"));
    }

    public static async createSearch(
        model: JsonModel,
        searches: SearchCriteria.Searches,
        typeId: Item.Id,
        itemIdArr?: any[],
        isNewSearch?: boolean,
        rootName?: string,
        makePub?: boolean,
        makeFav?: boolean,
        origModel?: JsonModel,
    ) {
        itemIdArr = itemIdArr || [];
        const tempSearches = SearchService.getTempSearchesInModel(model) || [];

        const promises = tempSearches.map((tempSearch) => {
            return SearchService.createSearch(searches[tempSearch], searches, typeId, itemIdArr).then(
                (searchDetails) => {
                    const step =
                        S25Util.propertyGetParentWithChildValue(model, "itemName", tempSearch) ||
                        S25Util.propertyGetParentWithChildValue(model, "itemId", tempSearch);
                    step.itemId = searchDetails.queryId;
                    step.itemName = searchDetails.queryName;
                },
            );
        });
        await Promise.all(promises);

        if (isNewSearch === false && origModel) model.query_id = origModel.query_id;

        const orig = S25Util.coalesce(origModel, null); //recursive searches are always new so no need for orig model
        const isNew = S25Util.coalesce(isNewSearch, true); //recursive searches are always new
        const name = S25Util.coalesce(rootName, "temp_" + S25Util.generateQuickGUID()); //recursive searches have temp_ prefix so they are invisible in 25live Classic and power
        const pub = typeof makePub === "undefined" ? false : makePub;
        const fav = S25Util.coalesce(makeFav, false); //recursive searches are never favorites

        return SearchService.putJsonSearch(typeId, model, orig, isNew, name, pub, fav, false).then(function (data) {
            itemIdArr.push(data.queryId);
            return { queryId: data.queryId, queryName: name, queryIdArr: itemIdArr };
        });
    }

    @Timeout
    public static deleteTempSearchByQueryId(queryId: number, objectTypeId: number) {
        return DataAccess.delete(
            DataAccess.injectCaller(
                "/search/temp.json?query_id=" + queryId + "&obj_id=" + objectTypeId,
                "SearchService.deleteTempSearchByQueryId",
            ),
        ).then(
            function (data) {
                return true;
            },
            function (error) {
                return false;
            },
        );
    }

    /**
     * Check whether a specified query includes specified items
     * @param itemType
     * @param queryId
     * @param itemIds
     * @Cached
     */
    public static async doesSearchIncludeItems<Id extends number>(
        itemType: Item.Ids,
        queryId: number,
        itemIds: Id[],
    ): Promise<Record<Id, boolean>> {
        const includes: Record<Id, boolean> = {} as Record<Id, boolean>;

        const setFromPromise = async (id: Id, promise: Promise<boolean>) => {
            includes[id] = await promise;
        };

        // Check cache
        const hits: Promise<void>[] = [];
        const misses: Id[] = [];
        for (const id of itemIds) {
            const key = CacheRepository.composeKey("SearchService", "doesSearchIncludeItem", [itemType, queryId, id]);
            const data = CacheRepository.get(key) as Promise<boolean>;

            if (data !== undefined) {
                hits.push(setFromPromise(id, data)); // Cache hit
            } else {
                misses.push(id); // Cache miss
            }
        }

        // Fetch uncached
        const dataPromise = SearchService._doesSearchIncludeItems(itemType, queryId, itemIds);

        // Cache new
        for (const miss of misses) {
            const key = CacheRepository.composeKey("SearchService", "doesSearchIncludeItem", [itemType, queryId, miss]);
            const itemPromise = dataPromise.then((data) => data[miss]);
            CacheRepository.put(key, itemPromise);
            hits.push(setFromPromise(miss, itemPromise));
        }

        // Wait for all promises and return
        await Promise.allSettled(hits);
        return includes;
    }

    public static async _doesSearchIncludeItems<Id extends number>(
        itemType: Item.Ids,
        queryId: number,
        itemIds: Id[],
    ): Promise<Record<Id, boolean>> {
        const prefixes: Record<number, string> = {
            [Item.Ids.Event]: "event",
            [Item.Ids.Location]: "space",
            [Item.Ids.Resource]: "resource",
            [Item.Ids.Organization]: "organization",
        };
        const prefix = prefixes[itemType];
        if (!prefix) return Object.fromEntries(itemIds.map((id) => [id, false])) as Record<Id, boolean>;

        const data = await DataAccess.get<ListResponse>(
            DataAccess.injectCaller(
                `/${prefix}s.json?scope=list&query_id=${queryId}&${prefix}_id=${itemIds.join("+")}`,
                "SearchService._doesSearchIncludeItems",
            ),
        );
        const items = S25Util.array.forceArray(data.list.item);

        const included = {} as Record<Id, boolean>;
        for (const item of items) included[item.id as Id] = true;
        for (const id of itemIds) {
            if (!included[id]) included[id] = false;
        }

        return included;
    }
}

type ListResponse = {
    list: {
        item: MaybeArray<{ name: string; id: number }>;
        engine: "accl";
    };
};

type JsonModel = SearchCriteria.Model & {
    query_id?: number;
    query_name?: string;
    query_type?: string;
    query_type_id?: NumericalString;
    favorite?: "T" | "F";
    last_mod_user?: string;
    last_mod_dt?: string;
    status?: "new" | "mod";
    _href?: string;
    href?: string;
};
