import { DataAccess, ResponseMessage } from "../dataaccess/data.access";
import { Cache } from "../decorators/cache.decorator";

export interface ScheduledEmail {
    scheduledEmailId?: number;
    objectTypeId: number;
    objectId: number;
    scenarioId: number;
    templateId: number;
    manualUuid: string;
    emailDocStr: string;
    daysFromNow?: number;
    daysFromEventStart?: number;
    daysFromEventEnd?: number;
    sendByUtc?: string;
    isSent?: boolean;
}

export class ScheduledEmailService {
    @Cache({ immutable: true, targetName: "ScheduledEmailService", expireTimeMs: 1000 })
    public static getScheduledEmails(itemTypeId: number, itemId: number): Promise<ScheduledEmail[]> {
        return DataAccess.get(`/scheduled/email/type/${itemTypeId}/id/${itemId}/queue.json`);
    }

    public static deleteScheduledEmail(scheduledEmailId: number): Promise<ResponseMessage> {
        return DataAccess.delete(`/scheduled/email/${scheduledEmailId}/queue.json`);
    }

    public static updateScheduledEmail(scheduledEmail: ScheduledEmail): Promise<ResponseMessage> {
        // emails with a past computed sendBy date are ignored; use forceSend to force the update
        return DataAccess.post("/scheduled/email/queue.json?forceSend=true", scheduledEmail);
    }
}
